import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  buttonView: {
    borderRadius: 12,
    paddingVertical: 15,
    paddingHorizontal: 50,
    marginHorizontal: 20,
    backgroundColor: '#4e3e71',
    marginBottom: 10,
    marginTop: 10
  },
  buttonViewFFF: {
    borderRadius: 12,
    borderWidth: 1,
    borderColor: '#4e3e71',
    paddingVertical: 15,
    paddingHorizontal: 50,
    marginHorizontal: 20,
    backgroundColor: '#fff',
    marginBottom: 10,
    marginTop: 10
  },
  purpleColor: { color: '#4e3e71' },
  buttonViewDisable: {
    borderRadius: 12,
    paddingVertical: 15,
    paddingHorizontal: 50,
    marginHorizontal: 20,
    backgroundColor: '#aa9cc9',
    marginBottom: 10,
    marginTop: 10
  },
  buttonText: {
    color: '#e8fafe',
    fontSize: 17,
    textAlign: 'center'
  },
  buttonTextFFF: {
    color: '#4e3e71',
    fontSize: 17,
    textAlign: 'center'
  },
  mobileHeaderView: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 24,
    backgroundColor: '#4e3e71'
  },
  mobileHeaderBackBtn: {
    height: 24,
    width: 24,
    marginLeft: 24
  },
  loader: {
    position: 'absolute', left: 0, right: 0, bottom: 0, top: 0
  },
  mobileHeaderText: {
    color: '#e8fafe',
    fontSize: 20,
    marginHorizontal: 'auto'
  },
  errorMsg: {
    color: 'red'
  },
  watermarkText: {
    // @ts-ignore
    position: 'absolute',
    top: '40%',
    left: '25%',
    transform: [{ rotate: '-17deg' }],
    opacity: 0.26,
    fontSize: 136,
    zIndex: 0,
    fontWeight: '600',
    color: '#b0b0b6'
  },
  watermarkTextCreateB: {
    // @ts-ignore
    position: 'absolute',
    top: '58%',
    left: '25%',
    transform: [{ rotate: '-17deg' }],
    opacity: 0.26,
    fontSize: 136,
    zIndex: 0,
    fontWeight: '600',
    color: '#b0b0b6'
  },
  watermarkTextMobile: {
    // @ts-ignore
    position: 'absolute',
    top: 280,
    left: 5,
    transform: [{ rotate: '-21deg' }],
    opacity: 0.26,
    fontSize: 50,
    zIndex: 0,
    fontWeight: '600',
    color: '#b0b0b6',
  },
  outerCard: {
    flex: 1,
    backgroundColor: 'white',
    borderWidth: 1,
    borderRadius: 12,
    borderColor: '#7f7f7f',
    margin: 15,
    flexDirection: 'column',
    position: 'relative',
    minHeight: 'auto',
    height: "auto"
  },
  watermarkRightCard: {
    flex: 0.8,
    borderRadius: 12,
    margin: 10,
    padding: 10,
    backgroundColor: 'rgba(168, 219, 230, 0.36)',
    flexDirection: 'row',
    minHeight: 'auto',
    height: '100%'
  },
  mobileSuregryCard: {
    backgroundColor: 'rgba(168, 219, 230, 0.36)',
    margin: 10,
    position: 'relative'
  }
});