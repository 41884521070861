// Customizable Area Start
import React, { useEffect } from "react";
import { StyleSheet, Text, TouchableOpacity, View } from "react-native";
import DesktopHeader from "./DesktopHeader";
import DesktopMenue from "./DesktopMenue";
import scale from "../../../framework/src/Scale";
import Scale from "../../../framework/src/Scale";
import GlobalCss from "../../../framework/src/GlobalCss";

const dummyDescription = "No description available";

export default function DesktopSurgeryDetails({
  navigation,
}: {
  navigation: any;
}) {
  const material = JSON.parse(localStorage.getItem("selectedMaterial") || "{}");
  const role_id = localStorage.getItem("role") || "";
  const navigateToEdit = () => {
    localStorage.setItem("editMaterialData", JSON.stringify(material));
    navigation.navigate("NewMaterial");
  };
  useEffect(() => {
    localStorage.removeItem("editMaterialData");
  }, []);

  return (
    <View style={styles.desktopContainer}>
      <DesktopHeader
        heading={material.attributes.name}
        navigation={navigation}
      />
      <View style={styles.bottomDesktopCard}>
        <DesktopMenue navigation={navigation} />
        <View style={{ flex: 0.85 }}>
          <View style={styles.outerCard}>
            <View style={{ flex: 1, flexDirection: "row" }}>
              <View>
                <img
                  src={
                    material.attributes.image
                      ? material.attributes.image
                      : require("../assets/dummyMaterial.png")
                  }
                  style={{
                    height: 350,
                    width: 350,
                    borderRadius: 12,
                    paddingTop: 10,
                  }}
                />
              </View>
              <View
                style={{
                  marginHorizontal: 24,
                  marginTop: 50,
                  flexDirection: "column",
                }}
              >
                <Text
                  numberOfLines={2}
                  style={{ fontSize: 30, color: "#4e3e71", width: Scale(220) }}
                >
                  {material.attributes.name}{" "}
                </Text>
                <Text
                  numberOfLines={2}
                  style={{ fontSize: 30, color: "#4e3e71", width: Scale(220) }}
                >
                  (Manufacturor :{" "}
                  {material.attributes.brand.data.attributes.name})
                </Text>
                <View style={styles.rygStatus}>
                  <View style={styles.rvgCircle}></View>
                    <Text>Optimal for price and sustainability</Text>
                </View>
                <Text
                  style={{
                    fontSize: 16,
                    color: "#4e3e71",
                    width: scale(200),
                    maxHeight: "100px",
                  }}
                >
                  {material.attributes.description
                    ? material.attributes.description
                    : dummyDescription}
                </Text>
              </View>
            </View>
            {role_id === "4" && (
              <View
                style={{ flexDirection: "row", justifyContent: "flex-end" }}
              >
                <TouchableOpacity
                  onPress={() => navigateToEdit()}
                  style={GlobalCss.buttonView}
                >
                  <Text style={GlobalCss.buttonText}>Edit Details</Text>
                </TouchableOpacity>
              </View>
            )}
          </View>
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  desktopContainer: {
    flex: 1,
    flexDirection: "column",
  },
  editBTn: {
    fontSize: 20,
    fontWeight: "bold",
    color: "#4e3e71",
    textDecorationLine: "underline",
  },
  assignTo: {
    flexDirection: "row",
    borderColor: "#e5e5e5",
    borderWidth: 1,
    borderRadius: 8,
    height: 50,
    marginVertical: 10,
    marginHorizontal: 20,
  },
  quantity: {
    flexDirection: "row",
    height: 50,
    // width: 108,
    // height: 32,
    borderRadius: 5,
    borderColor: "#e5e5e5",
    borderWidth: 1,
    marginVertical: 10,
    marginHorizontal: 10,
    justifyContent: "space-between",
  },
  btn: {
    fontSize: 30,
    color: "#4e3e71",
    paddingVertical: 2,
    paddingHorizontal: 10,
  },
  btn2: {
    fontSize: 20,
    color: "#4e3e71",
    padding: 2,
    backgroundColor: "#cce9e5",
    borderRadius: 4,
    margin: 5,
    paddingHorizontal: 10,
  },
  name: {
    color: "#4e3e71",
    paddingVertical: 5,
    fontSize: 16,
  },
  pickerContainer: {
    // borderBottomWidth: 1,
    // borderBottomColor: '#e5e5e5',
    paddingVertical: 10,
    marginHorizontal: 24,
    height: 200,
  },
  picker: {
    width: "100%",
    height: 50,
    color: "#4e3e71",
    backgroundColor: "rgb(249, 250, 251)",
    paddingHorizontal: 30,
    borderRadius: 5,
    borderWidth: 1,
    borderColor: "white",
  },
  listm: {
    overflow: "hidden",
    shadowColor: "#b2bac6",
    shadowRadius: 10,
    shadowOpacity: 1,
    borderRadius: 12,
    padding: 16,
    borderColor: "#f2f4f5",
    borderWidth: 1,
    marginHorizontal: 15,
    marginVertical: 10,
  },
  buttonNew2: {
    borderRadius: 8,
    paddingVertical: 10,
    paddingHorizontal: 20,
    height: 50,
    backgroundColor: "#4e3e71",
    // width: '20%',
  },
  buttonTextNew2: {
    color: "#fff",
    fontSize: 17,
    textAlign: "center",
  },
  buttonNew: {
    borderRadius: 8,
    height: 48,
    paddingVertical: 10,
    paddingHorizontal: 20,
    backgroundColor: "#fff",
    // width: '20%',
  },
  buttonTextNew: {
    color: "#4e3e71",
    fontSize: 17,
    textAlign: "center",
  },
  button: {
    borderRadius: 12,
    paddingVertical: 15,
    paddingHorizontal: 20,
    marginVertical: 10,
    backgroundColor: "#4e3e71",
    // width: '20%',
    marginHorizontal: 10,
  },
  buttonText: {
    color: "#e8fafe",
    fontSize: 17,
    textAlign: "center",
  },
  button0: {
    borderRadius: 12,
    paddingVertical: 15,
    paddingHorizontal: 20,
    marginVertical: 10,
    backgroundColor: "#aa9cc9",
    // width: '20%',
    marginHorizontal: 10,
  },
  buttonText0: {
    color: "#e8fafe",
    fontSize: 17,
    textAlign: "center",
  },
  listItem: {
    height: 88,
    width: 360,
    marginHorizontal: 16,
    borderColor: "#e5e5e5",
    borderWidth: 1,
    borderRadius: 12,
    marginVertical: 5,
    padding: 20,
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
  },
  listItem_active: {
    height: 88,
    width: 360,
    marginHorizontal: 16,
    borderColor: "#4e3e71",
    borderWidth: 1,
    borderRadius: 12,
    marginVertical: 5,
    padding: 20,
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
  },
  role: {
    color: "#b2bac6",
    paddingHorizontal: 11,
    width: scale(50),
  },
  names: {
    color: "#4e3e71",
    fontSize: 15,
    paddingHorizontal: 11,
    width: scale(60),
  },

  add: {
    flexDirection: "row",
    justifyContent: "flex-start",
  },
  resultText: {
    paddingVertical: 10,
    fontSize: 18,
  },
  card: {
    flex: 1,
    marginTop: 10,
    borderRadius: 6,
    elevation: 3,
    backgroundColor: "#fff",
    marginVertical: 6,
  },
  filterIcon: {
    height: 48,
    width: 48,
    borderRadius: 24,
    padding: 8,
    borderWidth: 1,
    borderColor: "#4e3e71",
    marginVertical: "auto",
    marginHorizontal: "auto",
  },
  bottomDesktopCard: {
    flex: 1,
    flexDirection: "row",
  },
  searchBox: {
    flexDirection: "row",
    marginVertical: 20,
    borderWidth: 1,
    borderColor: "#8a8a8a",
    borderRadius: 50,
  },
  input: {
    borderWidth: 0,
    flex: 1,
    paddingLeft: 10,
    height: 48,
    color: "#4e3e71",
    fontSize: 16,
    paddingStart: 20,
    width: 950,
  },
  outerCard: {
    flex: 1,
    elevation: 3,
    backgroundColor: "white",
    borderWidth: 1,
    borderRadius: 12,
    borderColor: "#7f7f7f",
    padding: 50,
    margin: 15,
    flexDirection: "column",
    justifyContent: "space-between",
  },
  rygStatus: {
    backgroundColor: 'rgba(15, 255, 80, 0.15)',
    alignSelf: 'flex-start',
    borderRadius : 20,
    padding : 10,
    flexDirection : 'row',
    alignItems : 'center',
    marginTop : 25,
    marginBottom : 25
  },
  rvgCircle : {
    height : 16,
    width : 16,
    borderRadius : 8,
    backgroundColor : '#0FFF50',
    marginRight : 10
  }
});
// Customizable Area End
