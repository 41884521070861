import React, { useState, useEffect } from "react";
// Customizable Area Start
import {
      StyleSheet,
      Text,
      TouchableOpacity,
      TextInput,
      View,
} from "react-native";
import DesktopHeader from "../../DesktopHeader";
import DesktopMenue from "../../DesktopMenue";
import scale, { verticalScale } from "../../../../../framework/src/Scale";
import Dialog from '@material-ui/core/Dialog';
import DateFnsUtils from '@date-io/date-fns';
import {
  KeyboardTimePicker,
  TimePicker,
  MuiPickersUtilsProvider,
  DatePicker
} from '@material-ui/pickers';
import { Formik} from "formik";
import * as yup from "yup";
import GlobalCss from "../../../../../framework/src/GlobalCss";
import { validation_Schema } from "./OutOfOffice";
import { getErrorMessage, patchAxiosAPI } from "../../../../../framework/src/Blocks/AxiosAPIBlock";
import CustomPopUp from "../../../../../framework/src/CustomPopUp";

export default function DesktopSurgeryDetails({ navigation }: { navigation: any }) {

      const [ dates, setDates] = useState({
            startDate: new Date(),
            startTime: new Date(),
            endDate: new Date(),
            endTime: new Date()
      })
      const [message, setMessage] = useState("");
      const [popup, setPopup] = useState(false)
      
      const ClosePopUp = () => {
      setPopup(false)
      }

      const { startDate, startTime, endDate, endTime} = dates

      // console.log(dates);

      const submitResponse = (start: any, end: any, email: any) => {
            let data = {
                  out_office:{
                    start_date: start,
                    end_date: end
                  }
                }
                console.log(data)
            let url = `/bx_block_materials/out_office?email=${email}`
            patchAxiosAPI(url, data).then((res:any) => {
                  if(res.error){
                        console.log(res.error)
                        let msg = getErrorMessage(res.data.errors[0])
                        console.log(res.data);
                        setPopup(true)
                        setMessage(msg)
                        // setLoader(false)
                  }else{
                        console.log(res.data)
                        setPopup(true)
                        setMessage(`Assigned to ${email} successfully`)
                  }
            })
      }

      return (
            <View style={styles.desktopContainer}>
                  <DesktopHeader heading={'Out of Office'} navigation={navigation} />
                  {popup && <CustomPopUp ClosePopUp={ClosePopUp} btnText={"Back"} message={message}/>}
                  <View style={styles.bottomDesktopCard}>
                        <DesktopMenue navigation={navigation} />
                        <View style={{ flex: 0.85}}>
                        <View style={styles.outerCard}>
                              <View>
                              <Text style={{ fontSize: 20, color: '#4e3e71', fontWeight: 'bold' }}>Out of Office</Text>
                              <Text  style={styles.enterText1}>Start Date & Time</Text>
                              <View style={{ flex: 1, flexDirection: 'row' }}>
                                    <View style={styles.inputs}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <DatePicker
                                    // disableFuture={true}
                                    disablePast={true}
                                    style={{marginLeft: 10}}
                                    openTo="date"
                                    format="MM/dd/yyyy"
                                    // label="Date of Birth"
                                    views={["year", "month", "date"]}
                                    value={startDate}
                                    onChange={(date: any) => setDates((preVal: any) => ({...preVal, startDate: date}))}
                                    />
                                    </MuiPickersUtilsProvider>
                                    </View>
                                    <View style={styles.inputs}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                          <TimePicker 
                                          autoOk 
                                          value={startTime}
                                          onChange={(time: any) => setDates((preVal: any) => ({...preVal, startTime: time}))} />
                                          </MuiPickersUtilsProvider>
                                    </View>
                              </View> 
                              <Text style={styles.enterText}>Start Date & Time</Text>
                              <View style={{ flex: 1, flexDirection: 'row' }}>
                                    <View style={styles.inputs}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <DatePicker
                                     disablePast={true}
                                    style={{marginLeft: 10}}
                                    openTo="date"
                                    format="MM/dd/yyyy"
                                    // label="Date of Birth"
                                    views={["year", "month", "date"]}
                                    value={endDate}
                                    onChange={(date: any) => setDates((preVal: any) => ({...preVal, endDate: date}))}
                                    />
                                    </MuiPickersUtilsProvider>
                                    </View>
                                    <View style={styles.inputs}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                          <TimePicker 
                                          autoOk 
                                          value={endTime}
                                          onChange={(time: any) => setDates((preVal: any) => ({...preVal, endTime: time}))} />
                                          </MuiPickersUtilsProvider>
                                    </View>
                              </View> 
                              <Text style={styles.enterText}>Enter Substitute Sales Rep</Text>
                              <Formik
              initialValues={{email: ''}}
              validationSchema={validation_Schema}
                onSubmit={(values, actions) => {
                    console.log(values)
                    let d1 = Date.parse(startDate.toLocaleDateString() + " " + startTime.toLocaleTimeString())
                    let d2 = Date.parse(endDate.toLocaleDateString() + " " + endTime.toLocaleTimeString())
                    submitResponse(d1, d2, values.email)
                }}
                >
                  {
                    ({ errors, touched ,handleChange, handleBlur, values, handleSubmit }) => (
                    <View>
                <View style={styles.textInput2}>
                    <TextInput placeholder="Enter Email Address" 
                    style={styles.textInput}
                        onBlur={handleBlur('email')}
                        placeholderTextColor="#b2bac6"
                        onChangeText={handleChange('email')}
                        value={values.email}
                    />
                </View>
                {touched.email && errors.email ?   <Text style={styles.errorMsg}>{errors.email}</Text> : null}

                <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'space-between'}}>

                                    <TouchableOpacity style={styles.button}>
                                          <Text style={styles.buttonText}>Cancel</Text>
                                    </TouchableOpacity>
                                    <TouchableOpacity onPress={() => handleSubmit()}
                                    
                                    style={styles.button0} >
                                          <Text style={styles.buttonText0}>Assign to sales rep</Text>
                                    </TouchableOpacity>
                                   
                              </View>
                </View>  
        )}</Formik>
                              
                              </View>
                            

                        </View>
                        </View>
                  </View>
                  
            </View>
      )
}

const styles = StyleSheet.create({
      desktopContainer: {
            flex: 1,
            flexDirection: 'column'
      },
      errorMsg:{ paddingVertical: 5,paddingHorizontal: 10,  color: 'red'},
      bottomDesktopCard: {
            flex: 1,
            flexDirection: 'row'
      },
      inputs: { flexDirection: 'column', flex: 0.48, marginEnd: 15},
      textInput: {
            color: '#4e3e71',
            paddingLeft: 10,
            paddingVertical: 10,
            borderBottomColor: '#b2bac6',
            borderBottomWidth: 1,
            marginHorizontal: 5
      },
      textInput2: {
            // color: '#b2bac6',
            width: '47%',
            // paddingLeft: 10,
            // paddingVertical: 10,
            // borderBottomColor: '#b2bac6',
            // borderBottomWidth: 1,
            // marginHorizontal: 5
      },
      names: {
            color: '#4e3e71',
            fontSize: 18,
            fontWeight: 'bold',
            paddingHorizontal: 11,
            width: scale(70),

      },
      topButton: { backgroundColor: '#4e3e71', fontSize: 16, color: '#ffff', paddingVertical: 5, paddingHorizontal: 10, borderRadius: 4, height: 35, width: 120 },
      outerCard: {
            flex: 1,
            minHeight: 600,
            backgroundColor: 'white',
            borderWidth: 1,
            borderRadius: 12,
            borderColor: '#7f7f7f',
            padding: 15,
            margin: 15,
            flexDirection: 'column',
            justifyContent: 'space-between'
      },
      enterText: {
            color: "#4e3e71",
            fontWeight: 'bold',
            paddingVertical: 10,
            fontSize: 16
      },
      enterText1: {
            color: "#4e3e71",
            fontWeight: 'bold',
            marginTop: 20,
            paddingVertical: 10,
            fontSize: 16
      },
      button: {
            marginHorizontal: 24,
            height: 54,
            paddingHorizontal: 80,
            backgroundColor: "#ffff",
            borderColor: '#4e3e71',
            borderWidth: 1,
            borderRadius: 12,
            justifyContent: "center",
            alignItems: "center",
            marginTop: 30,
            marginBottom: 10
      },
      buttonText: {
            fontSize: 17, color: "#4e3e71"
      },
      searchBox: {
            flex: 1,
            flexDirection: 'row',
            marginVertical: 20,
            borderWidth: 1,
            borderColor: '#8a8a8a',
            borderRadius: 50
      },
      input: {
borderWidth: 0,
            flex: 1,
            paddingLeft: 10,
            height: 48,
            color: '#4e3e71',
            fontSize: 16,
            paddingStart: 20,
            width: '100%'
      },
      add: {
            flexDirection: 'row',
            justifyContent: "center"
      },
      resultText: {
            paddingVertical: 10,
            fontSize: 18

      },
      card: {
            flex: 1,
            // height: 300,
            marginTop: 10,
            borderRadius: 6,
            elevation: 3,
            backgroundColor: '#fff',
            marginVertical: 6,
      },
      listItem: {
            // height: 88,
            maxWidth: 400,
            marginHorizontal: 20,
            borderColor: '#e5e5e5',
            borderWidth: 1,
            borderRadius: 4,
            marginVertical: 20,
            paddingVertical: 12,
            flex: 1,
            flexDirection: 'column',
            justifyContent: 'space-between'
      },
      button0: {
            marginHorizontal: 24,
            height: 54,
            paddingHorizontal: 60,
            backgroundColor: "#4e3e71",
            // borderColor: '#4e3e71',
            // borderWidth: 1,
            borderRadius: 12,
            justifyContent: "center",
            alignItems: "center",
            marginTop: 30,
            marginBottom: 10
      },
      buttonText0: {
            color: '#fff',
            fontSize: 17
      },

})