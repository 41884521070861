// Customizable Area Start
import React, {useState, useEffect } from 'react';
import {StyleSheet,
Text,
View,
TouchableOpacity,
Picker,
TextInput,
FlatList,
 } from 'react-native';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Collapse from '@material-ui/core/Collapse';
import Checkbox from '@material-ui/core/Checkbox';
import CancelIcon from '@material-ui/icons/Cancel';
import { Formik, Form, Field} from "formik";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import * as yup from "yup";
import ProgressBar from "./StepperComponent";
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  DatePicker
} from '@material-ui/pickers';
import { getAxiosAPI , getErrorMessage} from '../../../framework/src/Blocks/AxiosAPIBlock';
import { checkValues, SortData } from '../../../framework/src/Utilities';
import GlobalCss from '../../../framework/src/GlobalCss';


export const validation_Schema = yup.object({
      surgery_detail: yup.string().required("Details are required"),
      procedure_duration: yup.string().required("Time is required"),
      or_table: yup.string().required("O.R details are required"),
      first_name: yup.string().required("First name is required").matches(/[a-zA-Z]/, "Only alphabets are allowed with minimum length 1"),
      last_name: yup.string().required("Last name is required").matches(/[a-zA-Z]/, "Only alphabets are allowed with minimum length 1"),
      patient_type: yup.string().required("Please select this field"), 
      latex_allergies: yup.string().required("Please select this field"), 
      pr_op_antibiotics: yup.string().required("Please select this field"), 
      patient_gender: yup.string().required("Gender is required"), 
      patient_email: yup.string().email("Please enter a valid email").required("Email is required"),
      patient_phone: yup.string().matches(/^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/, "Invalid Format").min(10, "must be atleast 10 digits").required("Phone is required"),
    })




export default function BookingInfo({navigation}: {navigation: any}) {
      const order = JSON.parse(localStorage.getItem('order') || '{}')
      const [state1, setState1] = React.useState({
            Xray: order.imagings ? order.imagings.indexOf("X-ray") !== -1 ? true :  false : false,
            Carm: order.imagings ? order.imagings.indexOf("Carm") !== -1 ? true :  false : false,
            Microscope: order.imagings ? order.imagings.indexOf("Microscope") !== -1 ? true :  false : false,
          });
      const [query, setQuery] = useState("");
      const [query1, setQuery1] = useState("");
      const [searchData, setsearchData] = useState([]);
      const [items, setItems] = useState('');
      const [patientImage, setPatientImage] = useState(order.patient_image ? order.patient_image : '' );
      const [imageDemo , setImageDemo] = useState(order.patient_imageDemo ? order.patient_imageDemo : '');
      const [selectedDateP, setSelectedDateP] = useState(order?.patient_age ? new Date(order.patient_age * 1000   ) : new Date());

      const getBase64 = (file :any) => {
            return new Promise((resolve,reject) => {
               const reader = new FileReader();
               reader.onload = () => resolve(reader.result);
               reader.onerror = error => reject(error);
               reader.readAsDataURL(file);
            });
          }

          const handleDateChangeP = (date: any) => {
            setSelectedDateP(date);
          };

      const onImageChange = (event :any) => {
            setPatientImage({image: event.target.files[0], name: event.target.files[0].name, type: event.target.files[0].type})
            const file = event.target.files[0];
            getBase64(file).then((base64: any) => {
                  setImageDemo(base64);
            });
            }

      const handleChange1 = (event: any) => {
            setState1({ ...state1, [event.target.name]: event.target.checked });
            };


      const [cptCodes, setCptCodes] = useState([]);
      const [DiagnosisCodes, setDiagnosisCodes] = useState<any>([]);
      const [dreapsList, setDrapsList] = useState([])
      const [suturesList, setSuturesList] = useState([])
      const [sutureSizeList, setSutureSizeList] = useState([])

            const getCptCodeList = async () => {
                      let url = `/bx_block_code/order_code/cpt_codes_list`
                      await getAxiosAPI(url).then((res: any) => {
                            if(res.error){
                              let msg = getErrorMessage(res.data.errors[0])
                            } else {
                              setCptCodes(res.data.data.sort((a: any, b: any) => SortData(a.attributes.code ,b.attributes.code)))
                            }
                          })
                  }
            const getDiagnosisList =  async () => {
                      let url = `/bx_block_code/order_code/procedure_codes_list`
                      await getAxiosAPI(url).then((res: any) => {
                            if(res.error){
                              let msg = getErrorMessage(res.data.errors[0])
                            } else {
                              setDiagnosisCodes(res.data.data.sort((a: any, b: any) => SortData(a.attributes.code ,b.attributes.code)))
                            }
                          })
                  }
            const getDrapsList = async () => {
                      let url = `/bx_block_code/order_code/draps_list`
                      await getAxiosAPI(url).then((res: any) => {
                            if(res.error){
                              let msg = getErrorMessage(res.data.errors[0])
                            } else {
                              setDrapsList(res.data.data.sort((a: any, b: any) => SortData(a.attributes.code ,b.attributes.code)))
                            }
                          })
            }
            const getSuturesList =  async () => {
                      let url = `/bx_block_code/order_code/suture_list`
                      await getAxiosAPI(url).then((res: any) => {
                            if(res.error){
                              let msg = getErrorMessage(res.data.errors[0])
                            } else {
                              setSuturesList(res.data.data.sort((a: any, b: any) => SortData(a.attributes.code ,b.attributes.code)))
                            }
                          })
            }
            const getSuturesSizeList =  async () => {
                      let url = `/bx_block_code/order_code/suture_size_list`
                      await getAxiosAPI(url).then((res: any) => {
                            if(res.error){
                              let msg = getErrorMessage(res.data.errors[0])
                            } else {
                              setSutureSizeList(res.data.data.sort((a: any, b: any) => SortData(a.attributes.code ,b.attributes.code)))
                            }
                          })
                  }

      useEffect(() => {
            getCptCodeList();
            getDiagnosisList();
            getDrapsList()
            getSuturesList()
            getSuturesSizeList()
          }, []);

const [isString, setisString] = useState(false);    
const [isempty, setIsempty] = useState(false); 
const [isempty1, setIsempty1] = useState(false);

  const handleSearch = (text : any) => {
      setQuery(text)
      if(text !== '' && !isNaN(+text)){
            setIsempty(false)
            if(text !== "") {
                  const newData = cptCodes.filter((name: any) => {
                  return Object.values(name.attributes)
                        .join(" ")
                        .toLowerCase()
                        .includes(text.toLowerCase());
                  });
                  setsearchData(newData);                  
            } else {
              setsearchData(cptCodes);
            }
       
      } else if(text !== '' && isNaN(+text)) {
            setisString(true)
      } else if (scptCodes.lenght > 1){
            setIsempty(false)
      } else {
            setisString(false)
            setIsempty(false)
      }     
    };

    const [colorCode, setColorCode] = useState<any>([])  
    const [scptCodes, setsCptCodes]  = useState<any>([]);
      const selectCptCode = (code: any, status: any) => {
            let data = {
                  code: code,
                  material_available: status
          }
          setQuery('')
            setColorCode((preVal: any) => [...preVal, code])
            setsCptCodes((codes: any) => [...codes, data])
    }

    const selectDiagnosisCode = (idx: any ,code: any) => {
      const values = [...Diagnosisfields];
      values[idx].code = code;
      setDiagnosisFields(values);
      setQuery1('')
      
    }
    
    

    const [addDiagnosis, setAddDiagnosis] = useState([0]);
    const [Diagnosisfields, setDiagnosisFields] = useState([{ id: new Date().getTime().toString(), code: '', laterality: 'left',isOpen: true }]);

    function handleChangeDiagnosis(i:any, event:any) {
      const values = [...Diagnosisfields];
      values[i].code = event.target.value;
      setDiagnosisFields(values);
      setQuery1(event.target.value)      
      if(event.target.value !== "") {
            const newData = DiagnosisCodes.filter((name: any) => {
            return Object.values(name.attributes)
                  .join(" ")
                  .toLowerCase()
                  .includes(event.target.value.toLowerCase());
            });
            setsearchData(newData);            
      } else {
        setsearchData(DiagnosisCodes);
      }

    }

    function handleChangeLitrality(i:any, event:any) {
      const values = [...Diagnosisfields];
      values[i].laterality = event.target.value;
      setDiagnosisFields(values);
    }

    function handleAdd() {
      const values = [...Diagnosisfields];
      values.push({id: new Date().getTime().toString(), code: '', laterality: 'left',isOpen: false });
      setDiagnosisFields(values);
    }
     
    const handleCollapsed = (field :any) => { 
      let newdata: any = Diagnosisfields.map((x: any) => {
      if(x.id === field.id ) {
        x.isOpen = !x.isOpen
      } else {
        x.isOpen = false
      }
      return x
    })
    setDiagnosisFields(newdata)
    }


    useEffect(() => {
          if (order.cpt_codes) {
            setsCptCodes(order.cpt_codes) 
          }
          if (order.diagnosis_codes) {
            setDiagnosisFields(order.diagnosis_codes)
          }
          if (order.cptColorCodes) {
            setColorCode(order.cptColorCodes)
          }
    }, [])
    let freshCptCode = [...new Set(scptCodes)]
    const ListEmptyView = () => {
      return (
        <View>
          <Text style={styles.heading2}>No Record Found</Text>
          </View>
          )}

    
   
    
    
    const removeCptCode = (code : any) => {
          setsCptCodes(scptCodes.filter((item: any) => item.code !== code.code))
          setColorCode(colorCode.filter((item: any) => +item !== +code.code))
    }

    const saveData = (x:any) => {

      const order = JSON.parse(localStorage.getItem('order') || '{}')
      order.surgery_detail = x.surgery_detail
      order.latex_allergies = x.latex_allergies
      order.or_table = x.or_table
      order.patient_age =  Date.parse(selectedDateP.toLocaleDateString()) / 1000
      order.first_name = x.first_name.trim()
      order.last_name = x.last_name.trim()
      order.pr_op_antibiotics = x.pr_op_antibiotics
      order.procedure_duration = x.procedure_duration
      order.cpt_codes = freshCptCode
      order.patient_type = x.patient_type
      order.imagings = [state1.Xray ? 'X-ray': null, state1.Carm ? 'C-Arm': null, state1.Microscope ? 'Microscope': null]
      order.draps = x.draps
      order.sutures = x.Sutures
      order.diagnosis_codes = Diagnosisfields 
      order.insurance_carrier = x.insurance_carrier
      order.policy_member_id = x.policy_member_id
      order.authorization = x.authorization
      order.sutures_size = x.Sutures_size
      order.patient_gender = x.patient_gender
      order.patient_image = patientImage 
      order.patient_imageDemo = imageDemo
      order.patient_email = x.patient_email
      order.patient_phone = x.patient_phone
      order.cptColorCodes = colorCode
      order.physiotherapy = x.physiotherapy
      order.homecare = x.homecare
      order.benefits = x.benefits
      localStorage.setItem('order', JSON.stringify(order))
      navigation.navigate('ChooseMaterial')
    }

      return (

            <Formik
            initialValues={{ 
                  surgery_detail: checkValues(order.surgery_detail, ''),
                  procedure_duration: order.procedure_duration ? order.procedure_duration : '',
                  or_table: order.or_table ? order.or_table : '',
                  first_name: order.first_name ? order.first_name : '',
                  last_name: order.last_name ? order.last_name : '',
                  diagnosis_code: '',
                  patient_type: order.patient_type ? order.patient_type : 'Ambulatory',
                  latex_allergies: order.latex_allergies ? order.latex_allergies : 'Yes',
                  pr_op_antibiotics: order.pr_op_antibiotics ? order.pr_op_antibiotics : 'Yes',
                  insurance_carrier: order.insurance_carrier ? order.insurance_carrier : '',
                  policy_member_id: order.policy_member_id ? order.policy_member_id : '',
                  authorization: order.authorization ? order.authorization : '',
                  draps: order.draps ? order.draps : '',
                  Sutures: order.sutures ? order.sutures : '',
                  Sutures_size: order.sutures_size ? order.sutures_size : '',
                  patient_age: order.patient_age ? order.patient_age : '',
                  patient_gender: order.patient_gender ? order.patient_gender : '',
                  patient_email: order.patient_email ? order.patient_email : '',
                  patient_phone: order.patient_phone ? order.patient_phone : '',
                  physiotherapy: order.physiotherapy ? order.physiotherapy : 'Yes',
                  homecare: order.homecare ? order.homecare : 'Yes',
                  benefits: order.benefits ? order.benefits : ''
            }}
            validationSchema={validation_Schema}
              onSubmit={(values, actions) => {
                  if(scptCodes.length < 1 ){
                        setIsempty(true)
                      } else if(Diagnosisfields[0].code === '') {
                        setIsempty1(true)
                      } else {
                        saveData(values)
                      }
                
                

              }}
            >
                  {
                  ({ errors, touched ,handleChange, handleBlur, values, handleSubmit }) => (
                 
            <View style={styles.container}>
               <Text style={GlobalCss.watermarkTextMobile}>CONFIDENTIAL</Text>   
                              <View style={styles.headingView}>
                                    <TouchableOpacity style={styles.leftArrowIconWrapper} onPress={() => navigation.goBack()}>
                                          <img src={require("../assets/leftArrow1.png")} style={{
                                                height: 24, width: 24,
                                          }} />
                                    </TouchableOpacity>
                        <Text style={styles.heading}>Surgery Details</Text>
                  </View>
                  <View>
                 <ProgressBar activeStepCount={2}/>
                </View>
            <View>
            </View>
                  <View style={styles.info}>
                        <Text style={styles.headingH1}>Booking Information:</Text>
                        <RadioGroup aria-label="quiz" name="quiz" value={values.patient_type} onBlur={handleBlur('patient_type')} onChange={handleChange('patient_type')}>
                        <View style={{flexDirection: 'row' }}>
                        <FormControlLabel style={{ color: "#4e3e71"}}  value="Ambulatory"  control={<Radio style={{ color: "#4e3e71"}} />} label="Ambulatory" />
                        <FormControlLabel style={{ color: "#4e3e71"}}  value="Admitted" control={<Radio style={{ color: "#4e3e71"}} />} label="Admitted" />
                        </View>
                        </RadioGroup>
                        {touched.patient_type && errors.patient_type ?   <Text style={GlobalCss.errorMsg}>{errors.patient_type}</Text> : null}
                       
                       
                        <Text style={styles.headingH2}>Latex Allergies</Text>
                        <RadioGroup aria-label="quiz" name="quiz" onBlur={handleBlur('latex_allergies')} value={values.latex_allergies} onChange={handleChange('latex_allergies')}>
                        <View style={{flexDirection: 'row'}}>
                        <FormControlLabel style={{ color: "#4e3e71"}}  value="Yes" control={<Radio style={{ color: "#4e3e71"}} />} label="Yes" />
                        <FormControlLabel style={{ color: "#4e3e71"}}  value="No" control={<Radio style={{ color: "#4e3e71"}} />} label="No" />
                        <FormControlLabel style={{ color: "#4e3e71"}}  value="Unknown" control={<Radio style={{ color: "#4e3e71"}} />} label="Unknown" />
                        </View>
                        </RadioGroup>
                        {touched.latex_allergies && errors.latex_allergies ?   <Text style={GlobalCss.errorMsg}>{errors.latex_allergies}</Text> : null}

                        <View>
                              <TextInput 
                              placeholder="Surgery Details"
                              placeholderTextColor="#b2bac6"
                              style={styles.input}
                              onBlur={handleBlur('surgery_detail')}
                              onChangeText={handleChange('surgery_detail')}
                              value={values.surgery_detail}
                              />
                              {touched.surgery_detail && errors.surgery_detail ?   <Text style={GlobalCss.errorMsg}>{errors.surgery_detail}</Text> : null}

                               <TextInput 
                              placeholder="Length of Procedure"
                              placeholderTextColor="#b2bac6"
                              style={styles.input}
                              onBlur={handleBlur('procedure_duration')}
                              onChangeText={handleChange('procedure_duration')}
                              value={values.procedure_duration}
                              />
                              {touched.procedure_duration && errors.procedure_duration ?   <Text style={GlobalCss.errorMsg}>{errors.procedure_duration}</Text> : null}

                               <TextInput 
                              placeholder="O.R Table"
                              placeholderTextColor="#b2bac6"
                              style={styles.input}
                              onBlur={handleBlur('or_table')}
                              onChangeText={handleChange('or_table')}
                              value={values.or_table}
                              />
                              {touched.or_table && errors.or_table ?   <Text style={GlobalCss.errorMsg}>{errors.or_table}</Text> : null}
                        </View>
                        <Text style={styles.headingH2}>Imaging</Text>
                        <View style={{flexDirection: 'row'}}>
                        <FormControlLabel
                              control={
                              <Checkbox
                                    checked={state1.Xray}
                                    onChange={handleChange1}
                                    name="Xray"
                                    style={{ color: "#4e3e71"}} 
                              />
                              }
                              label="X-Ray"
                              style={{ color: "#4e3e71"}} 
                              />
                               <FormControlLabel
                              control={
                              <Checkbox
                                    checked={state1.Carm}
                                    onChange={handleChange1}
                                    name="Carm"
                                    style={{ color: "#4e3e71"}} 
                              />
                              }
                              label="C-Arm"
                              style={{ color: "#4e3e71"}} 
                              />
                               <FormControlLabel
                              control={
                              <Checkbox
                                    checked={state1.Microscope}
                                    onChange={handleChange1}
                                    name="Microscope"
                                    style={{ color: "#4e3e71"}} 
                              />
                              }
                              label="Microscope"
                              style={{ color: "#4e3e71"}} 
                              />
                              </View>

                        <View>
                  <Text style={styles.headingH2}>Disposables</Text>
                  <Text style={styles.inputlabel}>Drapes</Text>
                  <View style={styles.pickerContainer}>
                        <Picker
                              selectedValue={values.draps}
                              style={styles.picker}
                              onValueChange={handleChange('draps')}
                        >
                              <Picker.Item value={null} label='Select Drapes :-' />
                              {
                                    dreapsList.map((item: any) => (
                                          <Picker.Item value={item.attributes.name} label={item.attributes.name} />
                                    ))
                              }
                        </Picker>
                  </View>
                  <Text style={styles.inputlabel}>Sutures</Text>
                  <View style={styles.pickerContainer}>
                        <Picker
                              selectedValue={values.Sutures_size}
                              style={styles.picker}
                              onValueChange={handleChange('Sutures_size')}
                        >
                              <Picker.Item value={null} label='Size :-' />
                              {
                                    sutureSizeList.map((item: any) => (
                                          <Picker.Item label={item.attributes.size} value={item.attributes.size} />
                                    ))
                              }
                           
                              
                        </Picker>
                        </View>
                        <View style={styles.pickerContainer}>
                        <Picker
                              selectedValue={values.Sutures}
                              style={styles.picker}
                              onValueChange={handleChange('Sutures')}
                        >
                              <Picker.Item value={null} label='Select Sutures :-' />
                              {
                                    suturesList.map((item: any) => (
                                          <Picker.Item value={item.attributes.name} label={item.attributes.name} />
                                    ))
                              }
                        </Picker>
                        </View>
                  
                       
                        </View>

                        <View>
                        <Text style={styles.headingH2}>Pre-Op Antibiotics</Text>
                        <RadioGroup aria-label="quiz" name="quiz" onBlur={handleBlur('pr_op_antibiotics')} value={values.pr_op_antibiotics} onChange={handleChange('pr_op_antibiotics')}>
                        <View style={{flexDirection: 'row'}}>
                        <FormControlLabel style={{ color: "#4e3e71"}} value="Yes" control={<Radio style={{ color: "#4e3e71"}} />} label="Yes" />
                        <FormControlLabel style={{ color: "#4e3e71"}} value="No" control={<Radio style={{ color: "#4e3e71"}} />} label="No" />
                        </View>
                        </RadioGroup>
                        {touched.pr_op_antibiotics && errors.pr_op_antibiotics ?   <Text style={GlobalCss.errorMsg}>{errors.pr_op_antibiotics}</Text> : null}
                        </View>

                        <Text style={styles.headingH2}>Postop</Text>
                        <Text style={styles.inputlabel}>Physiotherapy</Text>
                        <RadioGroup aria-label="quiz" name="quiz" onBlur={handleBlur('physiotherapy')} value={values.physiotherapy} onChange={handleChange('physiotherapy')}>
                        <View style={{flexDirection: 'row'}}>
                        <FormControlLabel style={{ color: "#4e3e71"}} value="Yes" control={<Radio style={{ color: "#4e3e71"}} />} label="Yes" />
                        <FormControlLabel style={{ color: "#4e3e71"}} value="No" control={<Radio style={{ color: "#4e3e71"}} />} label="No" />
                        </View>
                        </RadioGroup>
                        <Text style={styles.inputlabel}>Homecare</Text>
                        <RadioGroup aria-label="quiz" name="quiz" onBlur={handleBlur('homecare')} value={values.homecare} onChange={handleChange('homecare')}>
                        <View style={{flexDirection: 'row'}}>
                        <FormControlLabel style={{ color: "#4e3e71"}} value="Yes" control={<Radio style={{ color: "#4e3e71"}} />} label="Yes" />
                        <FormControlLabel style={{ color: "#4e3e71"}} value="No" control={<Radio style={{ color: "#4e3e71"}} />} label="No" />
                        </View>
                        </RadioGroup>
                        
                        <Text style={styles.headingH1}>Patient Information</Text>
                        <View>
                        <TextInput 
                              placeholder="First Name"
                              placeholderTextColor="#b2bac6"
                              style={styles.input}
                              onBlur={handleBlur('first_name')}
                              onChangeText={handleChange('first_name')}
                              value={values.first_name}
                              />
                              {touched.first_name && errors.first_name ?   <Text style={GlobalCss.errorMsg}>{errors.first_name}</Text> : null}
                       
                        <TextInput 
                              placeholder="Last Name"
                              placeholderTextColor="#b2bac6"
                              style={styles.input}
                              onBlur={handleBlur('last_name')}
                              onChangeText={handleChange('last_name')}
                              value={values.last_name}
                              />
                              {touched.last_name && errors.last_name ?   <Text style={GlobalCss.errorMsg}>{errors.last_name}</Text> : null}
                              
                              <View style={{ paddingVertical: 10}}>
                              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <DatePicker
                                disableFuture={true}
                                style={{marginLeft: 10}}
                                openTo="date"
                                format="MM/dd/yyyy"
                                label="Date of Birth"
                                views={["year", "month", "date"]}
                                value={selectedDateP}
                                onChange={handleDateChangeP}
                              />
                              </MuiPickersUtilsProvider>
                              </View>
                              <TextInput 
                              placeholder="Patient Email"
                              placeholderTextColor="#b2bac6"
                              style={styles.input}
                          
                              onBlur={handleBlur('patient_email')}
                              onChangeText={handleChange('patient_email')}
                              value={values.patient_email}
                              />
                              {touched.patient_email && errors.patient_email ?   <Text style={GlobalCss.errorMsg}>{errors.patient_email}</Text> : null} 
                             
                              <TextInput 
                              placeholder="Patient Phone Number"
                              placeholderTextColor="#b2bac6"
                              style={styles.input}
                            
                              onBlur={handleBlur('patient_phone')}
                              onChangeText={handleChange('patient_phone')}
                              value={values.patient_phone}
                              />
                              {touched.patient_phone && errors.patient_phone ?   <Text style={GlobalCss.errorMsg}>{errors.patient_phone}</Text> : null} 
                              
                              <View style={styles.pickerContainer}>
                              <Picker
                                    selectedValue={values.patient_gender}
                                    style={styles.picker}
                                    onValueChange={handleChange('patient_gender')}
                              > 
                                    <Picker.Item value={null} label='Select gender' />
                                    <Picker.Item label="Male" value="Male" />
                                    <Picker.Item label="Female " value="Female" />
                                    <Picker.Item label="Other" value="Other" />
                                    
                              </Picker>
                              {touched.patient_gender && errors.patient_gender ?   <Text style={GlobalCss.errorMsg}>{errors.patient_gender}</Text> : null}    
                         </View>

                         <View style={styles.patientImage}>
                               {
                                     imageDemo ? 
                                     <img src={imageDemo} alt="preview image" style={{width: 60, height: 60, paddingTop: 10, borderRadius: '50%', }}/> :
                                     <img src={order?.patient_image ? order.patient_image : require("../assets/avatar-chat1.png")} style={{height: 60, width: 60, paddingTop: 10, borderRadius: '50%' }} />
                               }
                         <label htmlFor="file">
                 
                                    <View style={styles.editIcon}>
                                          <TouchableOpacity style={styles.buttonNew}>
                                          <Text style={styles.buttonTextNew}>Select Patient Image</Text>
                                          </TouchableOpacity>
                                    </View>
                                    
                                    </label>

                               <input 
                               type='file' 
                               id="file" 
                               style={{display:'none'}} 
                               name="patient_image"   
                               accept="image/*"
                               onChange={onImageChange}
                               />
                         </View>
                          <TextInput 
                              placeholder={freshCptCode.length > 0 ? "Select more codes or proceed" : "Select (CPT) codes"}
                              keyboardType='numeric'
                              placeholderTextColor={ freshCptCode.length > 0 ? '#00663d' : "#b2bac6"}
                              style={[styles.input, { borderColor: freshCptCode.length > 0 ? '#00663d' : 'red' }]}
                              value={query}
                              onChangeText={queryText => handleSearch(queryText)}
                              />
                              {isString ?   <Text style={GlobalCss.errorMsg}>Numbers are allowed</Text> : null}
                              {isempty ?   <Text style={GlobalCss.errorMsg}>Cpt codes are required</Text> : null}
                              {
                                    (query !== "" && searchData.length > 0 && !isString) ? 
                                    <FlatList 
                                    style={{height: 300,  backgroundColor: 'transparent' }}
                                data={query.length <1 ? cptCodes: searchData}
                                keyExtractor={(item: any, index:any) => index}
                                renderItem={({ item }) => (
                                      <View style={styles.cptcodesContainer}>
                                            <TouchableOpacity disabled={colorCode.includes(item.attributes.code) ? true : false} onPress={() => selectCptCode(item.attributes.code, item.attributes.material_available)}>
                                          <Text style={colorCode.includes(item.attributes.code) ? styles.codeIncludes : styles.codeNotIncludes}>{item.attributes.code} <Text style={styles.codeDesCPT}>{item.attributes.description ? (item.attributes.description).substring(0, 35) + "..." : "No description available"} </Text></Text>
                                          </TouchableOpacity>
                                         
                                      </View>
                                )}
                              /> : null

                              }
                               <FlatList 
                                data={freshCptCode}
                              horizontal={false}
                              numColumns={3}
                                keyExtractor={(item: any) => item.code}
                                renderItem={({ item }: {item :any}) => (
                                          <TouchableOpacity >
                                          <View style={styles.cptcodes}>
                                          <Text style={styles.cptText}>{item.code}</Text>
                                          <CancelIcon onClick={() => removeCptCode(item)} style={{ color: '#4e3e71', paddingLeft: "5px"}}/>
                                    </View>
                                          </TouchableOpacity>
                                )}
                              />

                              
                              <Text style={styles.headingH2}>Diagnosis Code</Text>
                               
                              {
                                   Diagnosisfields.map((field, idx) => {
                                        return (
                                              <View>
                                          <TouchableOpacity onPress={() => handleCollapsed(field)}>
                                          <View style={styles.drawers}>
                                                <Text style={styles.headingH2}>Diagnosis Code {idx+1}</Text>
                                                {field.isOpen ? <ArrowDropUpIcon style={{color : '#4e3e71', paddingTop: 5 }}/> : <ArrowDropDownIcon style={{color : '#4e3e71', paddingTop: 5 }}/> }
                                          </View>
                                          </TouchableOpacity>
                                             
                                          <Collapse in={field.isOpen}>
                                              <View key={`${field}-${idx}`} style={{marginHorizontal: 10, }}>
                              <TextInput 
                              //  onBlur={(e) => {
                              //       if (!this.validateEmail(this.state.text_input_email)) {
                              //          // not a valid email
                              //       } else {
                              //          // valid email
                              //       }
                              //     }
                              // }
                              placeholder="Diagnosis Code"
                              placeholderTextColor="#b2bac6"
                              style={styles.input}
                              value={field.code}
                              onChange={e => handleChangeDiagnosis(idx, e)}
                              />
                               {isempty1 ?   <Text style={GlobalCss.errorMsg}>Diagnosis Code are required</Text> : null}
                               {
                                    (query1 !== "" && searchData.length) ? 
                              <FlatList 
                              style={{height: 300,  backgroundColor: 'transparent' }}
                                data={query1.length <1 ? DiagnosisCodes : searchData}
                                ListEmptyComponent={ListEmptyView()}
                                keyExtractor={(item: any, index:any) => index}
                                renderItem={({ item }) => (
                                      <View style={styles.cptcodesContainer}>
                                            <TouchableOpacity onPress={() => selectDiagnosisCode(idx, item.attributes.code)}>
                                          <Text style={DiagnosisCodes.includes(item.attributes.code) ? styles.diagnosisCodeInc : styles.diagnosisCodeNotInc}>{item.attributes.code} <Text style={{ color: '#626f84', fontSize: 14 }}>{item.attributes.description ? (item.attributes.description).substring(0, 35) + "..." : "No description available"} </Text></Text>
                                          </TouchableOpacity>
                                         
                                      </View>
                                )}
                              /> : null

                              }
                              <Text style={styles.headingH2}>Laterality</Text>
                              <RadioGroup aria-label="quiz" name="quiz" value={field.laterality} onChange={e => handleChangeLitrality(idx, e)}>
                              <View style={{flexDirection: 'row'}}>
                              <FormControlLabel style={{ color: "#4e3e71"}}  value="left" control={<Radio style={{ color: "#4e3e71"}} />} label="Left" />
                              <FormControlLabel style={{ color: "#4e3e71"}}  value="right" control={<Radio style={{ color: "#4e3e71"}} />} label="Right" />
                              <FormControlLabel style={{ color: "#4e3e71"}}  value="both" control={<Radio style={{ color: "#4e3e71"}} />} label="Both" />
                              </View>
                              
                              </RadioGroup>
                            
                                              </View>
                                              </Collapse>
                                              </View>
                                        )
                                  })  
                              }
                              
                                    

                              <TouchableOpacity onPress={() => handleAdd()}>
                                    
                              <View style={styles.buttonNew}>
                              <Text style={styles.buttonTextNew}> Add More Diagnosis Codes</Text>
                              </View>
                        </TouchableOpacity>

                              
                              <Text style={styles.headingH2}>Insurance Information</Text>
                              <TextInput 
                              placeholder="Insurance carrier"
                              placeholderTextColor="#b2bac6"
                              style={styles.input}
                              onBlur={handleBlur('insurance_carrier')}
                              onChangeText={handleChange('insurance_carrier')}
                              value={values.insurance_carrier}
                              />
                                  <TextInput 
                              placeholder="Policy/member id"
                              placeholderTextColor="#b2bac6"
                              style={styles.input}
                              onBlur={handleBlur('policy_member_id')}
                              onChangeText={handleChange('policy_member_id')}
                              value={values.policy_member_id}
                              />
                                  <TextInput 
                              placeholder="Authorization"
                              placeholderTextColor="#b2bac6"
                              style={styles.input}
                              onBlur={handleBlur('authorization')}
                              onChangeText={handleChange('authorization')}
                              value={values.authorization}
                              />
                                  <TextInput 
                              placeholder="Benefits"
                              placeholderTextColor="#b2bac6"
                              style={styles.input}
                              onBlur={handleBlur('benefits')}
                              onChangeText={handleChange('benefits')}
                              value={values.benefits}
                              />

                       

                        </View>
                       
                        <View>
                        <TouchableOpacity onPress={() => handleSubmit()} >
                              <View style={styles.button}>
                              <Text style={styles.buttonText}>Continue</Text>
                              </View>
                        </TouchableOpacity>
                        </View>

                  </View>

            </View>    
             )}
            </Formik> 
      )
      }

const styles = StyleSheet.create({
      container: {
            flex: 1,
            flexDirection: 'column',
            position: 'relative'
            },
            headingView: {
            flexDirection: 'row',
            alignItems: 'center',
            paddingVertical: 27,
            backgroundColor: '#4e3e71',
            marginBottom: 20,
            },
            diagnosisCodeNotInc: {backgroundColor: '#e2e5e9', color: "#4e3e71", fontSize: 18, paddingHorizontal: 10, borderRadius: 5},
            diagnosisCodeInc: {backgroundColor: '#4e3e71', color: "#fff", fontSize: 18, paddingHorizontal: 10, borderRadius: 5},
            codeDesCPT: { color: '#626f84', fontSize: 14 },
            codeNotIncludes: {backgroundColor: '#e2e5e9', color: "#4e3e71", fontSize: 18, paddingHorizontal: 10, borderRadius: 5},
            codeIncludes:{backgroundColor: '#4e3e71', color: "#fff", fontSize: 18, paddingHorizontal: 10, borderRadius: 5},
      heading2: {
            marginVertical: 20,
            marginHorizontal: 'auto',
            color: '#b2bac6',
            fontSize: 24,
            fontWeight: 'bold',
            flexDirection : 'row',
            justifyContent: 'center'
          },
      patientImage:{
          flexDirection: 'row',
          marginHorizontal: 10
      //     justifyContent: 'space-between',
      },
      editIcon:{
            flex: 1,
            flexDirection: 'column',
            // marginVertical: 30,
            // marginHorizontal: 50
      },
      leftArrowIconWrapper: {
            width: 24,
            height: 24,
            position: "absolute",
            left: 24
      },
                heading:{
                  color: '#e8fafe',
                  fontSize: 20,
                  marginHorizontal: 'auto'       
                },
                drawers: {
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  paddingHorizontal: 20,
                  paddingVertical: 10
                }, 
                ctpCodesRow:{
                  flex: 1,
                  flexDirection: 'row'
                },
                cptcodesContainer:{
                  flex: 1,
                  backgroundColor: '#eff8ff',
                  marginHorizontal: 10,
                  paddingVertical: 5,
                  paddingHorizontal: 5, 
                  borderBottomColor: '#8f8f8f',
                  borderBottomWidth: 1,
                },
                pickerContainer :{
                      borderBottomWidth: 1,
                      borderBottomColor: '#e5e5e5',
                      paddingVertical: 10
      },
      inputlabel: {
            color: '#4e3e71',
            fontSize: 18,
            paddingVertical: 5,
      },
                picker: {
                      width: '100%',
                      height: 50,
                      color: '#4e3e71',
                      borderRadius: 5,
                      borderWidth: 1,
                      borderColor: 'transparent',
                      backgroundColor: 'transparent'
                },
                cptcodes: {
                  flexDirection: 'row',
                  backgroundColor: '#e2e5e9',
                  borderRadius: 8,
                  paddingVertical:8,
                  paddingHorizontal: 10,
                  marginHorizontal:5,
                  marginVertical: 5
                },
                cptText:{
                  color: '#4e3e71',
                  paddingHorizontal: 2
                },
                bar:{
                      margin: 20,

                },
                collapse: {
                  padding: 50,
                  backgroundColor: '#e6e6e6',

                },
                info: {
                  paddingHorizontal: 20,
                },
                headingH1: {
                      color: '#4e3e71',
                      fontSize: 25,
                      marginVertical: 20
                },
                headingH2: {
                  color: '#4e3e71',
                  fontSize: 20,
                  fontWeight:'bold',
                  marginVertical: 15
                },
                input: {
borderWidth: 0,
                  flex: 1,
                  marginVertical: 20,
                  paddingVertical:10,
                  paddingLeft: 10,
                  fontSize: 18,
                  color: '#112950',
                  borderBottomWidth: 1,
                  borderBottomColor: '#e5e5e5'
                },
                dropdown: {
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  padding: 15,
                  backgroundColor: '#eff8ff',
                  color: '#0579ed',
                  borderRadius: 5
                },
                button: {
                  borderRadius: 8,
                  paddingVertical: 10,
                  paddingHorizontal: 30,
                  backgroundColor: '#4e3e71',
                  marginVertical: 20,
                },
                buttonText: {
                  color: 'white',
                  fontSize: 20,
                  textAlign: 'center',
                },
                buttonNew: {
                  borderRadius: 8,
                  paddingVertical: 10,
                  paddingHorizontal: 5,
                  backgroundColor: '#4e3e71',
                  marginVertical: 20,
                  marginHorizontal: 50
                },
                buttonTextNew: {
                  color: 'white',
                  fontSize: 20,
                  textAlign: 'center',
                },
})
// Customizable Area End