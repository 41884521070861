import React, {useState} from 'react';
import {StyleSheet,
Text,
View,
TouchableOpacity,
 } from 'react-native';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import ChatIcon from '@material-ui/icons/Chat';
import Collapse from '@material-ui/core/Collapse';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowForwardRoundedIcon from '@material-ui/icons/ArrowForwardRounded';
import CallIcon from '@material-ui/icons/Call';




export default function SurgeryDetails({navigation}: {navigation: any}) {
      const [checked, setChecked]  = useState(true);
      const [checked1, setChecked1]  = useState(true);
      const [checked2, setChecked2]  = useState(true);
      const [checked3, setChecked3]  = useState(true);
      // const [data, getData] = useState([]);

      const user = JSON.parse(localStorage.getItem('previous_booking') || '{}')
      const role_idNew = localStorage.getItem('role')
      
      
      console.log(user);
      const date = user.attributes.booking_datetime
      var d = new Date(date);

      
      const chats = (id: any) => {
            // console.log(id)
            const chatApiParams = {
                  material_id : id,
                  order_id: user.id
            }
            localStorage.setItem('chatApiParams', JSON.stringify(chatApiParams))
            navigation.navigate('SharedNotes')
      }
      


      return (
            <View style={styles.container}>
                  <View style={styles.headingView}>
                        <TouchableOpacity style={styles.leftArrowIconWrapper} onPress={() => navigation.goBack()}>
                              <img src={require("../assets/leftArrow1.png")} style={{
                                    height: 24, width: 24,
                              }} />
                        </TouchableOpacity>
            <Text style={styles.heading}> Booking Summary </Text>
            </View>
            <View style={styles.card}>
            <View style={styles.horizontalBorder}>

                  <Text style={{  color: '#7f7f7f',fontSize: 17}}>Hospital Name: </Text>
                  <Text style={styles.color}>{user.attributes.hospital.data?.attributes.name}</Text> 
                  <Text style={styles.newBtn}>{user.attributes.patient_type}</Text>

                  </View>
                  <View style={styles.profileDetails}>
                  <img src={require("../assets/patient.png")} style={{height: 92, width: 92}}/>    
                  <View style={{marginHorizontal: 16, flexDirection: 'column', marginVertical: 'auto'}}>     
                  <Text style={{fontSize: 16, color: '#7f7f7f' }}>Patient Name: <Text style={{fontSize: 20, color: '#4e3e71'}}>{user.attributes.first_name+" "+user.attributes.last_name} </Text>  </Text>
                  <Text style={{fontSize: 16, color: '#7f7f7f' }}>Age:  <Text style={{fontSize: 20, color: '#4e3e71'}}>{user.attributes.patient_age} </Text> </Text>
                  </View>
                  </View>
                  

                  <View style={styles.buttonsRow}>
                  <TouchableOpacity>
                        <View style={styles.button}>
                        <Text style={styles.buttonText}>{d.toLocaleDateString()}</Text>
                        </View>
                  </TouchableOpacity>
                  <TouchableOpacity>
                        <View style={styles.button}>
                        <Text style={styles.buttonText}>{d.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</Text>
                        </View>
                  </TouchableOpacity>
                 
                  </View>

                  <View>
                        
                        <View style={styles.detailsList1}>
                            {role_idNew === '1' ? 
                            <View>
                            <TouchableOpacity onPress={() => checked ? setChecked(false) : setChecked(true)}>
                        <View style={{flexDirection: 'row', justifyContent: 'space-between'}}>
                        <Text style={{fontWeight: 'bold', padding: 5 ,  color: '#4e3e71', fontSize: 20}}>Imaging</Text>
                        {checked ? <ArrowDropUpIcon /> : <ArrowDropDownIcon /> }
                        </View>
                        </TouchableOpacity> 
                        <Collapse in={checked}>
                              {
                                    user.attributes.imagings.map((x:any, y:any) => {
                                          return (
                                                <View style={styles.drawerItem}>
                                                <Text style={styles.info}>{y+1}) {x}</Text>
                                          </View>
                                          )
                                    })
                              }
                        </Collapse> 
                        </View>
                        : null }
                        </View>
                        <View style={styles.detailsList1}>
                              
                        <TouchableOpacity onPress={() => checked1 ? setChecked1(false) : setChecked1(true)}>
                        <View style={{flexDirection: 'row', justifyContent: 'space-between'}}>
                        <Text style={{fontWeight: 'bold', padding: 5 , color: '#4e3e71', fontSize: 20}}>Surgery equipment(Facility)</Text>
                        {checked1 ? <ArrowDropUpIcon /> : <ArrowDropDownIcon /> }
                        </View>
                        </TouchableOpacity>
                        <Collapse in={checked1}>
                              {
                                    user.attributes.ordered_materials.data.filter((x: any) => x.attributes.assign_to_type === "BxBlockHospital::Hospital").map((x:any, y:any) => {
                                          return (
                                                <View style={styles.drawerItem}>
                                                <Text style={{ color: '#7f7f7f', flex: 0.5, fontSize: 17}}>{y+1}) {x.attributes.materials.data.attributes.name}</Text>
                                                <FiberManualRecordIcon style={{color: 'blue', flex: 0.2}}/>
                                               <TouchableOpacity style={{flex: 0.1}} onPress={() => chats(x.attributes.material_id)}>
                                                <img src={require("../assets/chatIcon.png")} style={{ height: 15, width: 15, paddingTop: 5}}/>
                                               </TouchableOpacity>
                                                <Text style={{ color: '#7f7f7f', flex: 0.2,fontSize: 17, paddingLeft: 20}}>{x.attributes.quantity}</Text>
                  
                                          </View>
                                          )
                                    })
                              }
                        </Collapse>

                        <TouchableOpacity onPress={() => checked2 ? setChecked2(false) : setChecked2(true)}>
                        <View style={{flexDirection: 'row', justifyContent: 'space-between'}}>
                        <Text style={{fontWeight: 'bold', padding: 5 , color: '#4e3e71', fontSize: 20}}>Surgery equipment(Salesrep)</Text>
                        {checked2 ? <ArrowDropUpIcon /> : <ArrowDropDownIcon /> }
                        </View>
                        </TouchableOpacity>
                        
                        <Collapse in={checked2}>   
                              {
                                    user.attributes.ordered_materials.data.filter((x:any) => x.attributes.assign_to_type === "AccountBlock::Account").map((x:any,  y: any) => {
                                          return (
                                                <View style={styles.drawerItem}>  
                                               <Text style={{ color: '#7f7f7f', flex: 0.5,fontSize: 17}}>{y+1}) {x.attributes.materials.data.attributes.name}</Text>
                                                <FiberManualRecordIcon style={{color: 'blue', flex: 0.2}}/>
                                                <ChatIcon style={{flex: 0.1}} onClick={() => chats(x.attributes.material_id)}/>
                                                <Text style={{ color: '#7f7f7f',fontSize: 17, flex: 0.2, paddingLeft: 20}}>{x.attributes.quantity}</Text>      
                                          </View>
                                          )
                                    })
                              }
                       
                        </Collapse>
                       
                        
                        </View>
                    

                        <TouchableOpacity onPress={() => navigation.navigate('Tracking2')}>
                              <View style={styles.button1}>
                              <Text style={styles.buttonText1}>Checkoff Material</Text>
                              </View>
                        </TouchableOpacity>
                       
                     
                        
                  </View>
            </View>

            </View>
      )
}

const styles = StyleSheet.create({
      container: {
            flex: 1,
            backgroundColor: "#fff",
            flexDirection: 'column'
          },
          headingView: {
            flexDirection: 'row',
            alignItems: 'center',
            paddingVertical: 15,
            backgroundColor: '#4e3e71',
            marginBottom: 20,
     
      },
      leftArrowIconWrapper: {
            width: 24,
            height: 24,
            position: "absolute",
            left: 24
      },
          newBtn: {
            backgroundColor: '#4e3e71',
            paddingVertical: 5,
            paddingHorizontal: 10,
            borderRadius: 12,
            color: '#fff',
            fontSize: 12,
            marginHorizontal: 5
          },
          button1: {
            borderRadius: 12,
            paddingVertical: 15,
            paddingHorizontal: 50,
            marginHorizontal: 20,
            backgroundColor: '#4e3e71',
            marginBottom: 10,
            marginTop: 10
          },
          buttonText1: {
            color: '#e8fafe',
            fontSize: 17,
            textAlign: 'center',
          },
          heading:{
            color: '#e8fafe',
            fontSize: 20,
            marginHorizontal: 'auto'       
          },
          topHeading: {
            paddingTop: 40,
            fontSize: 18,
            color: '#0778df',
            paddingVertical: 5
          },
          info: {
            color: '#7f7f7f',
            paddingVertical: 5,
            fontSize: 17,
      },
          color: {
            color: '#4e3e71',
            fontSize: 17,
          },
          drawers: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            paddingHorizontal: 20,
            paddingVertical: 10
          }, 
          drawerItem: {
            paddingHorizontal: 5,
            flexDirection: 'row',
            marginVertical: 10,
            justifyContent: 'space-between'

          },
          drawerItem2: {
                paddingVertical: 5,
            marginHorizontal: 20,
            flexDirection: 'row',
            justifyContent: 'space-between',
            borderBottomWidth: 2,
            borderBottomColor: '#d8d8d8'
          },
      //     material: {
      //       marginHorizontal: 20,
      //       flexDirection: 'row',
      //       justifyContent: 'space-between',
      //     },
          textB: {
            fontSize: 20,
            fontWeight: 'bold'
          },      
          BottomInfo: {
            paddingHorizontal: 20,
            paddingVertical: 20,
            flexDirection: 'row',
            justifyContent: 'space-between'
          },    
          material: {
            padding: 5,
            justifyContent: 'space-between',
            flexDirection: 'row',
            color: '#0778df'
          },      
          detailsList1: {
            padding: 5,
            borderBottomColor: '#939ca3',
            borderBottomWidth: 1,
          }, 
          detailsList2: {
            margin: 20,
            fontWeight: 'bold',
            color: '#0778df',
            justifyContent: 'space-between',
            flexDirection :'row',
            borderBottomWidth: 1,
            borderColor: '#939ca3'

          },
          detailsList3: {
            margin: 20,
            flexDirection: 'row',
            justifyContent: 'space-between'

          },
          details: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginVertical: 20,
            marginHorizontal: 10
          },      
      //     color: {
      //       color: '#0778df',
      //     },
          buttonsRow: {
            flexDirection: 'row',
            justifyContent: 'center',
            paddingLeft: 20,
            paddingRight: 20
          },
          button: {
            borderRadius: 18,
            paddingVertical: 5,
            paddingHorizontal: 15,
            marginHorizontal: 10,
            marginVertical: 5,
            backgroundColor: '#4e3e71',
          },
          buttonText: {
            color: '#cce9e5',
            fontSize: 15,
            textAlign: 'center',
          },
          horizontalBorder: {
            borderBottomColor: '#c1c1c1',
            borderBottomWidth: 1,
            paddingVertical: 15,
            paddingHorizontal: 5,
            flexDirection: 'row',
            // justifyContent: 'center',
          },
          profileDetails:{
            flexDirection: 'row',   
            margin: 16
          },
          card : {
            backgroundColor: '#f0f9f8',    
            margin: 24 
          },
})