// Customizable Area Start
import React, { useState, useEffect } from "react";
import {
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  TextInput,
} from "react-native";
import {
  getAxiosAPI,
  getErrorMessage,
  patchAxiosAPI,
  postAxiosAPI,
} from "../../../framework/src/Blocks/AxiosAPIBlock";
import FlagIcon from "@material-ui/icons/Flag";

export default function ChatBox({ navigation }: { navigation: any }) {
  const [data, setData] = useState([]);
  const [text, setText] = useState("");
  const user = JSON.parse(localStorage.getItem("chatApiParams") || "{}");

  const TodaysDate = new Date().toLocaleDateString();
  const yesterdayDate = new Date(Date.now() - 864e5).toLocaleDateString();

  const getConversation = async () => {
    let url = `/bx_block_chat/conversation/load_conversation?order_id=${
      user.order_id
    }&material_id=${user.material_id}`;
    await getAxiosAPI(url).then((res: any) => {
      if (!res.error) {
        setData(res.data.data.attributes.messages.data);
      }
    });
  };

  useEffect(() => {
    getConversation();
  }, []);

  const SendConversation = async () => {
    if (text) {
      const postData = {
        material_id: user.material_id,
        order_id: user.order_id,
        content: text,
      };
      let url = "/bx_block_chat/conversation/send_message";
      await postAxiosAPI(url, postData).then((res: any) => {
        if (res.error) {
          let msg = getErrorMessage(res.data.errors);
          console.log(msg);
        } else {
          console.log(res.data);
          setData(res.data.data.attributes.messages.data);
          setText("");
        }
      });
    } else {
      console.log("empty");
    }
  };

  const flagMessage = async (id: any) => {
    const postData = {
      flagged: true,
      id: id,
    };

    let url = "/bx_block_chat/conversation/update_message";
    await patchAxiosAPI(url, postData).then((res: any) => {
      if (res.error) {
        let msg = getErrorMessage(res.data.errors);
        console.log(msg);
      } else {
        console.log(res.data);
        setData(res.data.data.attributes.messages.data);
      }
    });
  };

  const UserName = JSON.parse(localStorage.getItem("loggedinUserInfo") || "{}");
  function capitalize(input: any) {
    let words = input.split(" ");
    let CapitalizedWords: any = [];
    words.forEach((element: any) => {
      CapitalizedWords.push(
        element[0].toUpperCase() + element.slice(1, element.length)
      );
    });
    return CapitalizedWords.join(" ");
  }

  const getTime = (time: any) => {
    let d = new Date(time);
    return d.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  };
  let datesArray: any = [];

  const getDate = (time: any) => {
    let d = new Date(time);
    let newdate = d.toLocaleDateString();
    if (datesArray.includes(newdate)) {
      return null;
    } else {
      datesArray.push(newdate);
      return newdate;
    }
  };

  return (
    <View style={styles.container}>
      <View style={styles.Top}>
        <TouchableOpacity
          style={styles.leftArrowIconWrapper}
          onPress={() => navigation.goBack()}
        >
          <img
            src={require("../assets/leftArrow1.png")}
            style={{
              height: 24,
              width: 24,
            }}
          />
        </TouchableOpacity>
        <img
          src={
            UserName.profile_image
              ? UserName.profile_image
              : require("../assets/avatar-chat1.png")
          }
          style={{ height: 48, width: 48, borderRadius: "50%" }}
        />
        <Text style={styles.name}>
          {UserName.first_name + " " + UserName.last_name}
        </Text>
      </View>
      <View style={styles.ChatBox}>
        {/* <ScrollView> */}
        <View>
          {data.map((x: any) => {
            let renderDate = getDate(x.attributes.created_at);
            return (
              <View key={x.id}>
                {renderDate ? (
                  <Text style={styles.chatDate}>
                    {" "}
                    {renderDate === TodaysDate
                      ? "Today"
                      : renderDate === yesterdayDate
                      ? "Yesterday"
                      : renderDate}{" "}
                  </Text>
                ) : null}

                {x.attributes.message_owner.data.attributes.email ===
                UserName.email ? (
                  <View style={styles.msgs}>
                    <View style={styles.sendMsgContainer}>
                      <Text style={styles.userName}>
                        {x.attributes.message_owner.data.attributes.first_name}
                      </Text>
                      <Text style={styles.sendMsg}>{x.attributes.content}</Text>
                      <Text style={styles.sendMsgTime}>
                        {getTime(x.attributes.created_at)}
                        <TouchableOpacity onPress={() => flagMessage(x.id)}>
                          <img
                            src={
                              x.attributes.flagged
                                ? require("../assets/flag_white.png")
                                : require("../assets/flag.png")
                            }
                            style={{ height: 17, width: 17 }}
                          />
                        </TouchableOpacity>
                      </Text>
                    </View>
                    <img
                      src={
                        x.attributes.message_owner.data.attributes.profile_image
                          ? x.attributes.message_owner.data.attributes
                              .profile_image
                          : require("../assets/avatar-chat2.png")
                      }
                      style={{
                        height: 32,
                        width: 32,
                        marginTop: 10,
                        borderRadius: "50%",
                      }}
                    />
                  </View>
                ) : (
                  <View style={styles.Rmsgs}>
                    <img
                      src={
                        x.attributes.message_owner.data.attributes.profile_image
                          ? x.attributes.message_owner.data.attributes
                              .profile_image
                          : require("../assets/avatar-chat2.png")
                      }
                      style={{
                        height: 32,
                        width: 32,
                        marginTop: 10,
                        borderRadius: "50%",
                      }}
                    />
                    <View style={styles.receivedMsgContainer}>
                      <Text style={styles.othersName}>
                        {x.attributes.message_owner.data.attributes.first_name}
                      </Text>
                      <Text style={styles.receivedMsg}>
                        {x.attributes.content}
                      </Text>
                      <Text style={styles.receivedMsgTime}>
                        <TouchableOpacity onPress={() => flagMessage(x.id)}>
                          {x.attributes.flagged ? (
                            <FlagIcon
                              style={{ fontSize: "17px", color: "#000" }}
                            />
                          ) : (
                            <img
                              src={require("../assets/flag.png")}
                              style={{ height: 17, width: 17 }}
                            />
                          )}
                        </TouchableOpacity>

                        {getTime(x.attributes.created_at)}
                      </Text>
                    </View>
                  </View>
                )}
              </View>
            );
          })}
        </View>
        <View style={styles.typeRow}>
          <TextInput
            multiline
            style={styles.input}
            placeholder="Write a reply..."
            placeholderTextColor="#737373"
            value={text}
            onChangeText={(value) => setText(value)}
          />
          <TouchableOpacity onPress={() => SendConversation()}>
            <img
              src={require("../assets/send.png")}
              style={{ height: 25, width: 25, paddingTop: 10 }}
            />
          </TouchableOpacity>
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
    flexDirection: "column",
  },
  bottomIcons: {
    backgroundColor: "#fff",
    margin: 15,
    padding: 10,
    borderRadius: 10,
  },
  userName: {
    color: "#fff",
    textDecorationLine: "underline",
    fontSize: 10,
    fontWeight: "bold",
  },
  othersName: {
    color: "#4e3e71",
    textDecorationLine: "underline",
    fontSize: 10,
    fontWeight: "bold",
  },
  leftArrowIconWrapper: {
    // width: 24,
    // height: 24,
    marginVertical: "auto",
    paddingRight: 20,
    // position: "absolute",
    // left: 24
  },
  typeRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    backgroundColor: "#fff",
    marginVertical: 10,
  },
  input: {
    borderWidth: 0,
    height: 51,
    width: 327,
    // marginTop: 5,
    marginVertical: 10,
    backgroundColor: "#fff",
    borderBottomColor: "#e5e5e5",
    borderBottomWidth: 1,
    paddingLeft: 10,
  },
  sendMsg: {
    color: "#f2f4f5",
    fontSize: 16,
  },
  sendMsgTime: {
    color: "#f2f4f5",
    fontSize: 12,
    fontWeight: "bold",
  },
  receivedMsgTime: {
    color: "#112950",
    fontSize: 12,
    fontWeight: "bold",
  },
  sendMsgContainer: {
    // flex: 1,
    flexDirection: "column",
    backgroundColor: "#4e3e71",
    // alignContent: 'flex-end',
    alignItems: "flex-end",
    borderRadius: 12,
    maxWidth: 240,
    padding: 10,
    marginVertical: 10,
    marginHorizontal: 10,
  },
  receivedMsg: {
    color: "#112950",
    fontSize: 16,
  },
  receivedMsgContainer: {
    backgroundColor: "#f2f4f5",
    borderRadius: 12,
    maxWidth: 240,
    padding: 10,
    marginVertical: 10,
    marginHorizontal: 10,
  },
  msgs: {
    flexDirection: "row",
    justifyContent: "flex-end",
    // marginVertical: 'auto',
  },
  Rmsgs: {
    flexDirection: "row",
    justifyContent: "flex-start",
    // marginVertical: 'auto',
  },
  chatDate: {
    color: "#b4b6b8",
    fontSize: 14,
    marginHorizontal: "auto",
    marginVertical: 10,
  },
  ChatBox: {
    flex: 1,
    justifyContent: "space-between",
    marginHorizontal: 24,
  },
  callIcon: {
    padding: 10,
    borderRadius: 10,
    backgroundColor: "#e2f0fe",
  },
  Top: {
    flexDirection: "row",
    paddingHorizontal: 24,
    paddingVertical: 15,
    backgroundColor: "#4e3e71",
  },
  text1: {
    flexDirection: "row",
  },
  name: {
    color: "#fff",
    fontSize: 20,
    marginHorizontal: 30,
    marginVertical: "auto",
  },
  image: {
    height: 50,
    width: 50,
    backgroundColor: "#0778df",
    borderRadius: 50,
    color: "#fff",
  },
});
// Customizable Area End
