import React, {useState } from 'react';
import {StyleSheet,
Text,
View,
TouchableOpacity,
TouchableHighlight ,
TextInput,
Button,
Image,
ScrollView,
TouchableWithoutFeedback,
Platform } from 'react-native';
// import { Feather } from '@expo/vector-icons';
import Checkbox from '@material-ui/core/Checkbox';

export default function SelectManufacture4({navigation}: {navigation: any}) {
      const [checked, setChecked] = useState(false);

      const handleChange = (event: any) => {
        setChecked(event.target.checked);
      };
      
      return (
            <View style={styles.container}>
                   <View style={styles.searchBox}>
                  {/* <Feather style={styles.searchIcon} name="search" size={24} color="#939ca3" /> */}
                  <TextInput 
                       style={styles.input}
                        placeholder="Search"
                        placeholderTextColor="#939ca3"
                 />
                 </View>
                 <View style={styles.card}>
                        <View style={styles.outerCard}>
                        <View style={styles.innerCard}>

                        </View>
                        <View>
                        <Text style={styles.addressText}>
                                          <Text style={styles.cardHeading}>Manufacturer: KTS{'\n'}</Text>
                                          <Text style={styles.paraDis}>4260 Benson Park Drive {'\n'} YOUNG AMERICA {'\n'} LO - Minnesota</Text>
                                          </Text>
                                          <View style={styles.checkBox}>
                                          <Checkbox
                                          checked={checked}
                                          onChange={handleChange}
                                          style={{color: '#0579ed'}}
                                          inputProps={{ 'aria-label': 'primary checkbox' }}
                                          />
                                          </View>
                                          </View>
                                    </View>

                                    <View style={styles.outerCard}>
                        <View style={styles.innerCard}>

                        </View>
                        <View>
                        <Text style={styles.addressText}>
                                          <Text style={styles.cardHeading}>Manufacturer: KTS{'\n'}</Text>
                                          <Text style={styles.paraDis}>4260 Benson Park Drive {'\n'} YOUNG AMERICA {'\n'} LO - Minnesota</Text>
                                          </Text>
                                          <View style={styles.checkBox}>
                                          <Checkbox
                                          checked={checked}
                                          onChange={handleChange}
                                          style={{color: '#0579ed'}}
                                          inputProps={{ 'aria-label': 'primary checkbox' }}
                                          />
                                          </View>
                                          </View>
                                    </View>

                                   
                           
                 </View>
                 
                 <TouchableOpacity onPress={() => navigation.navigate('Dashboard4')}>
                  <View style={styles.button}>
                  <Text style={styles.buttonText}>Continue</Text>
                  </View>
            </TouchableOpacity>
            </View>

            )
};
      
const styles = StyleSheet.create({
            container: {
                  flex: 1,
                  backgroundColor: "#eff8ff",
                  flexDirection: 'column',
              
                },
                checkBox: {
                 flexDirection: 'row',
                 justifyContent: 'flex-end'
                },      
                searchBox: {
                  marginHorizontal: 20,
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderWidth: 2,
                  borderRadius: 50,
                  borderColor: '#939ca3',
                  marginVertical: 50,
                  height: 50
            },
                searchIcon: {
                  paddingLeft: 10
                }, 
                input: {
borderWidth: 0,
                  flex: 1,
                  paddingLeft: 10,
                },
                card: {
                  flex: 1,
                  backgroundColor: '#fff',
                  marginVertical: 20
                },
                cardContent: {
                  marginHorizontal: 18,
                  marginVertical: 20,
                },
                outerCard : {
                  flexDirection: 'row',
                  marginTop:20,
                  borderRadius: 10,
                  elevation: 3,
                  backgroundColor: '#eff8ff',
                  marginHorizontal: 20,
                  marginVertical: 6,
                  position: 'relative'
                },
                innerCard: {
                  margin:10,
                  borderRadius: 6,
                  elevation: 3,
                  backgroundColor: '#fff',
                  padding: 50,
      
                },
                addressText: {
                      marginTop: 20,
                      marginLeft: 10
                },
                cardHeading: {
                  marginTop: 30,
                  color: '#0579ed',
                  fontSize: 20,
                  fontWeight: 'bold',
                  justifyContent: 'center'
            },
            paraDis: {
                  paddingTop: 30,    
                  color: '#0579ed'
                },  
                button: {
                  borderRadius: 8,
                  paddingVertical: 10,
                  paddingHorizontal: 50,
                  backgroundColor: '#0579ed',
                  marginTop: 50,
                  marginHorizontal: 10,
                  marginBottom: 20,
                },
                buttonText: {
                  color: 'white',
                  fontSize: 20,
                  textAlign: 'center',
                },  
})