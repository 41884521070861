import React from "react";
import {
  View,
  Text,
  Image,
  StyleSheet,
  FlatList,
  TouchableOpacity,
  TextInput
} from "react-native";
import SearchIcon from "@material-ui/icons/Search";
import ValidateManagerController from './ValidateManagerController'
import { StackActions } from "react-navigation";
import DesktopDesign from "./DesktopValidateManager";
export default class ValidateManagerBlock extends ValidateManagerController {


  renderSearch = () => {
    return (
      <View style={styles.searchBox}>
        <TextInput
          style={styles.input}
          placeholder="Search"
          autoCapitalize="none"
          autoCorrect={false}
          placeholderTextColor="#4e3e71"
          value={this.state.query}
          onChangeText={queryText => this.handleSearch(queryText)}
        />
        <Image
          source={require("../../../assets/searchIcon.png")}
          style={styles.searchIcon}
        />
      </View>
    );
  };

  renderItem = (item: any) => {
    const data = item && item.attributes
    return (
      <TouchableOpacity
        style={styles.itemWrapper}
        onPress={() => {
          localStorage.setItem('managerData', JSON.stringify(item))
          this.props.navigation.navigate("ValidateManagerDetail");
        }}
      >
        <Image source={{uri:data.profile_image}} style={styles.itemImage} />
        <View style={{ marginLeft: 14 }}>
      <Text style={styles.doctorName}>{data.first_name} {data.last_name}</Text>
          <Text style={styles.hospitalName}>{data.hospitals && data.hospitals.length>0&& data.hospitals[0].name}</Text>
        </View>
      </TouchableOpacity>
    );
  };
  render() {
    return (
      window.screen.width < 950 ? 
      <View style={styles.container}>
        <View style={styles.headerWrapper}>
          <TouchableOpacity
            onPress={() => this.props.navigation.goBack()}
            style={styles.leftArrowIconWrapper}
          >
            <Image
              source={require("../../../assets/leftArrow.png")}
              style={styles.leftArrowIcon}
            />
          </TouchableOpacity>
          <Text style={styles.headerTitle}>Manager Information</Text>
        </View>
        <View style={styles.subWrapper}>
          <Text style={styles.doctorList}>Manager List</Text>
          {this.renderSearch()}
          <FlatList
            data={this.state.query.length <1 ? this.state.managersData: this.state.searchData}
            renderItem={({ item }) => this.renderItem(item)}
          />
        </View>
        </View> :
        <View>
          <DesktopDesign navigation={this.props.navigation} />
        </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#FFFFFF"
  },
  headerWrapper: {
    backgroundColor: "#4e3e71",
    height: 72,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center"
  },
  leftArrowIconWrapper: {
    width: 24,
    height: 24,
    position: "absolute",
    left: 24
  },
  leftArrowIcon: {
    width: 24,
    height: 24,
  },
  headerTitle: {
    color: "#e8fafe",
    fontSize: 20
  },
  subWrapper: {
    marginHorizontal: 24
  },
  doctorList: {
    color: "#4e3e71",
    fontSize: 18,
    fontWeight: "bold",
    marginTop: 30
  },
  itemWrapper: {
    flexDirection: "row",
    height: 97,
    borderRadius: 12,
    borderWidth: 1,
    borderColor: "#d0d0d0",
    alignItems: "center",
    marginTop: 16
  },
  itemImage: {
    height: 70,
    width: 70,
    borderRadius: 3,
    backgroundColor: "#d0d0d0",
    marginLeft: 24
  },
  doctorName: { color: "#4e3e71", fontSize: 17 },
  hospitalName: { color: "#8e9aab", fontSize: 14 },
  searchBox: {
    flexDirection: "row",
    borderWidth: 1,
    borderColor: "#8a8a8a",
    borderRadius: 24,
    justifyContent: "center",
    alignItems: "center",
    paddingHorizontal: 17,
    marginTop: 19
  },
  searchIcon: {
    height: 17,
    width: 17
  },
  input: {
borderWidth: 0,
    height: 48,
    fontSize: 16,
    color: "#4e3e71",
    width: "100%"
  }
});
