import React, { useState, useEffect } from "react";
// Customizable Area Start
import {
      StyleSheet,
      Text,
      TouchableOpacity,
      View,
      ActivityIndicator,
      FlatList
} from "react-native";
import DesktopHeader from "../../DesktopHeader"
import DesktopMenue from "../../DesktopMenue";
import AddNewMaterial from "./NewMaterilDataFunctional";
import { getAxiosAPI, getErrorMessage } from "../../../../../framework/src/Blocks/AxiosAPIBlock";
import scale, { verticalScale } from "../../../../../framework/src/Scale";


export default function DesktopSurgeryDetails({ navigation }: { navigation: any }) {

      const [select, setSelect] = useState({
            newMaterial: true,
            assignedmaterial: false,
      })
      const EditMaterial = JSON.parse(localStorage.getItem('editMaterialData') || '{}')
      const [data, setData] = useState([]);
      const [dataReq, setDataReq] = useState([]);
      const [query, setQuery] = useState("");
      const order = JSON.parse(localStorage.getItem('order') || '{}');
      const role_id = localStorage.getItem('role') || ''

      const [offset, setOffset] = useState(0);
      const [loader, setLoader] = useState({
            req:false,
            catalogue: false
      });

      const [message, setMessage] = useState("");
      const [popup, setPopup] = useState(false)

      useEffect(() => {
            getReqMaterials();
      }, [offset]);

      const getReqMaterials = async (search: any = '') => {
             setLoader((pre: any) => ({...pre, req:true}))
            let url = `/bx_block_materials/material_request`  
            await getAxiosAPI(url).then((res: any) => {
                  if(res.data.errors){
                    let msg = getErrorMessage(res.data.errors[0])
                    console.log(res.data);
                    setPopup(true)
                    setMessage(msg)
                    setDataReq(res.data.data)
                     setLoader((pre: any) => ({...pre, req:false}))
                  } else {
                  console.log(res.data.data)
                  setDataReq(res.data.data)
                  setLoader((pre: any) => ({...pre, req:false}))
                  //   console.log(res.data.errors[0].message);
                  }
                })
            }


      console.log(data)
      const navigateTo = (item : any) => {
            localStorage.setItem("editMaterialData", JSON.stringify(item))
            setSelect({
                  newMaterial: true,
                  assignedmaterial: false,
            })
      }

      return (
            <View style={styles.desktopContainer}>
                  <DesktopHeader heading={EditMaterial.attributes ?  'Edit Material' : "New Material"} navigation={navigation} />
                  <View style={styles.bottomDesktopCard}>
                        <DesktopMenue navigation={navigation} />
                        <View style={{ flex: 0.85}}>
                        <View style={styles.outerCard}>
                        <View style={{flexDirection: 'row',}}>
                                    <TouchableOpacity style={select.newMaterial ? { borderBottomColor: '#4e3e71', borderBottomWidth: 2, marginRight: 20 } : {marginRight: 20} } onPress={() => setSelect({
                                          newMaterial: true,
                                          assignedmaterial: false,
                                    })}>
                                          <Text style={select.newMaterial ? styles.topbutton_active : styles.topbutton}>{EditMaterial.attributes ? "Edit" : "New"} Material</Text>
                              </TouchableOpacity>
                                    <TouchableOpacity style={select.assignedmaterial ? { borderBottomColor: '#4e3e71', borderBottomWidth: 2, marginRight: 20 } : { marginRight: 20 } } onPress={() => setSelect({
                                          newMaterial: false,
                                          assignedmaterial: true,
                                    })}>
                                          <Text style={select.assignedmaterial ? styles.topbutton_active : styles.topbutton}>Requested Material Details</Text>
                              </TouchableOpacity>
                              </View>
                              
                              {
                                    select.newMaterial ? 
                                    <View>
                                        <AddNewMaterial navigation={navigation}/>  
                                    </View>      : 
                                          <View style={{ margin: 20 }}>
                                                {
                                                      loader.req ? 
                                                      <ActivityIndicator
                                                      style={{ margin: 'auto', height: 400, width: 100, }}
                                                      size="large" color="#4e3e71" />
                                                      :
                                                      dataReq?.length ? 
                                                      <FlatList
                                                      data={dataReq}
                                                      ListEmptyComponent={<Text style={styles.heading}>No Record Found</Text>}
                                                      horizontal={false}
                                                      numColumns={4}
                                                      keyExtractor={(item: any) => item.id}
                                                      renderItem={({ item }) => (
                                                      <TouchableOpacity onPress={() => navigateTo(item)}>
                                                            <View style={styles.listm}>
                                                                  {
                                                                        item.attributes.image ? <img src={item.attributes.image} style={{ height: 80, width: 100, borderRadius: 12 }} /> :
                                                                  <img src={require("../../../assets/dummyMaterial.png")} style={{ height: 100, width: 100, borderRadius: 12 }} />}
                                                                  <View>
                                                                  <Text numberOfLines={3} style={styles.materialName}>{item.attributes.name}</Text>
                                                                  <Text numberOfLines={3} style={styles.materialStatus}>Pending</Text>
                                                                  </View>
                                                            </View>
                                                      </TouchableOpacity>
                                                      )}
                                                />
                                                      :
                                                      <Text style={styles.heading}>No Record Found</Text>
                                                }
                                              
                                          </View>
                              }
                        </View>
                        </View>
                  </View>
            </View>
      )
}

const styles = StyleSheet.create({
      desktopContainer: {
            flex: 1,
            flexDirection: 'column'
      },
      priceView: {flex: 1, flexDirection: 'row', justifyContent: 'space-between'},
            leftArrowIconWrapper: {
            width: 24,
            height: 24,
            position: "absolute",
            left: 24
            },
            price: {
            color: '#b2bac6',
            paddingVertical: 5,
            fontSize: 13,
            textDecorationLine: 'line-through',
            width: scale(40)
            },
            discount_price: {
            color: '#4e3e71',
            paddingVertical: 5,
            fontSize: 14,
            width: scale(40)
            },
      name: {
            color: '#4e3e71',
            paddingVertical: 5,
            fontSize: 12,
            width: scale(40)
      },
      materialName: {
            color: '#4e3e71',
            marginVertical: 10,
            marginHorizontal: 15,
            fontSize: 18,
            fontWeight: '700',
            width: scale(40)
      },
      materialStatus: {
            backgroundColor: '#4e3e71',
            color: '#ffff',
            paddingHorizontal: 10,
            paddingVertical: 5,
            borderRadius: 6,
            marginVertical: 10,
            marginHorizontal: 15,
            fontSize: 18,
            fontWeight: '700',
            width: 91
      },
      listm: {
            flexDirection:'row',
            shadowColor: '#b2bac6',
            width: 300,
            shadowRadius: 5,
            shadowOpacity: 1,
            borderRadius: 12,
            padding: 16,
            borderColor: '#f2f4f5',
            borderWidth: 1,
            marginHorizontal: 8,
            marginVertical: 20
      },
      add: {
            flexDirection: 'row',
            justifyContent: "flex-start"
      },
      resultText: {
            paddingVertical: 10,
            fontSize: 18

      },
      card: {
            flex: 1,
            marginTop: 10,
            borderRadius: 6,
            elevation: 3,
            backgroundColor: '#fff',
            marginVertical: 6,
      },
      searchBox: {
            flexDirection: 'row',
            marginVertical: 20,
            borderWidth: 1,
            borderColor: '#8a8a8a',
            borderRadius: 50
      },
      input: {
borderWidth: 0,
            flex: 1,
            paddingLeft: 10,
            height: 48,
            color: '#4e3e71',
            fontSize: 16,
            paddingStart: 20,
            width: 950
      },
      heading: {
            marginTop: 100,
            marginHorizontal: 'auto',
            color: '#b2bac6',
            fontSize: 24,
            fontWeight: 'bold',
            flexDirection: 'row',
            justifyContent: 'center'
      },
      bottomDesktopCard: {
            flex: 1,
            flexDirection: 'row'
      },
      topbutton_active: {
            fontSize: 20,
            color: '#4e3e71',
            paddingBottom: 5
      },
      topbutton: {
            fontSize: 20,
            color: '#7f7f7f',
            paddingBottom: 5
      },
      outerCard: {
            flex: 1,
            margin: 15,
            flexDirection: 'column'
      }

})