import { View, StyleSheet, Text } from "react-native";
import React from 'react'
export const RygCircle = ({ backgroundColor }: { backgroundColor: string }) => {
    return (
          <View style={[styles.rygCircle, { backgroundColor }]} />
    );
};

export const RvgStatus = ({ category }: { category: {name:string, bgColor : string} }) =>{
    return(
          <View style={styles.rygStatus}>
                <RygCircle backgroundColor={category.bgColor} />
                <Text style={styles.popoverItem}>{category.name}</Text>
          </View>
    );
}


const styles = StyleSheet.create({
    rygCircle : {
        height : 12,
        width : 12,
        borderRadius : 50,
    },
    rygStatus : {
        display  :"flex",
        width : "auto",
        flexDirection  :"row",
        alignItems : "center",
        marginBottom : 10
    },
    popoverItem: {
        fontSize: 16,
        color: '#333',
        marginLeft : 15
    }
})