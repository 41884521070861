// Customizable Area Start
import React, { useState, useRef, useEffect } from "react";
import {
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  ActivityIndicator,
  TextInput,
  FlatList,
  ScrollView,
} from "react-native";
import {
  getBookingDate,
  getBookingTime,
  handleCondition,
  hideName,
  SortData,
} from "../../../framework/src/Utilities";
import Scale from "../../../components/src/Scale";
import { getAxiosAPI } from "../../../framework/src/Blocks/AxiosAPIBlock";
import CustomPopUp from "../../../framework/src/CustomPopUp";

export default function ExBooking({
  navigation,
  route,
}: {
  navigation: any;
  route: any;
}) {
  const [data, setData] = useState([]);
  const [query, setQuery] = useState("");
  const [loader, setLoader] = useState(true);
  const role_id = localStorage.getItem("role");

  const [status, setStatus] = useState("");

  const [DateList, setDateList] = useState<any>([]);

  const [message, setMessage] = useState("");
  const [popup, setPopup] = useState(false);
  const [offset, setOffset] = useState(0);

  const flatlistRef = useRef<any>();

  const getItemLayout = (data: any, index: any) => ({
    length: 65,
    offset: 65 * index,
    index,
  });

  const getorders = async (
    date: any = Date.parse(new Date().toLocaleDateString())
  ) => {
    setLoader(true);
    let url;
    if (date) {
      const startDate = date;
      const endDate = date + 86399999;
      url = `/bx_block_order/orders?start_day=${startDate}&end_day=${endDate}&status=${status}&search=${query}&limit=10&offset=${offset}`;
    } else {
      url = `/bx_block_order/orders?status=${status}&search=${query}&limit=10&offset=${offset}`;
    }
    await getAxiosAPI(url).then((res: any) => {
      if (res.error) {
        setPopup(true);
        setMessage(res.data);
        setLoader(false);
      } else {
        setData(
          res.data.data.sort((a: any, b: any) =>
            SortData(
              a.attributes.booking_datetime,
              b.attributes.booking_datetime
            )
          )
        );
        setLoader(false);
      }
    });
  };

  useEffect(() => {
    if (query || status || offset) {
      getorders();
    }
  }, [query, status, offset]);

  useEffect(() => {
    const userStatus = localStorage.getItem("userStatus");
    if (role_id === "2" || role_id === "3") {
      if (userStatus === "pending") {
        navigation.goBack();
      } else {
        getorders();
      }
    } else {
      getorders();
    }

    const daysInMonth = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).getDate();
const currentMonthDates2 = Array.from({ length: daysInMonth }, (x, i) => {
  const currentDate = new Date(new Date().getFullYear(), new Date().getMonth(), i + 1);
  return currentDate;
});

    let datesData = currentMonthDates2.map((x: any) => ({
      date: new Date(x).toDateString(),
      checked:
        new Date(x).toDateString() === new Date().toDateString()
          ? true
          : false,
    }));

    setDateList(datesData);
  }, []);

  useEffect(() => {
    if (DateList.length) {
      scrollToIndex();
    }
  }, [DateList]);

  const goToNextPage = (item: any) => {
    localStorage.setItem("previous_booking", JSON.stringify(item));
    navigation.navigate("SurgeryDetails");
  };

  const handleSearch = (text: any) => {
    setQuery(text);
  };

  const [buttons, setButtons] = useState([
    { isSelected: false, name: "Confirmed" },
    { isSelected: false, name: "Pending" },
  ]);
  const showConfirmedBookings = (name: any) => {
    if (name === "Confirmed") {
      setButtons([
        { isSelected: true, name: "Confirmed" },
        { isSelected: false, name: "Pending" },
      ]);
      setStatus("confirmed");
    } else {
      setButtons([
        { isSelected: false, name: "Confirmed" },
        { isSelected: true, name: "Pending" },
      ]);
      setStatus("pending");
    }
  };
  const ListEmptyView = () => {
    return (
      <View>
        <Text style={styles.heading2}>No Booking Found</Text>
      </View>
    );
  };

  const BookingStatus = (item: any) => {
    if (
      item.attributes.confirmed_by_bc === true &&
      item.attributes.confirmed_by_material_manager === true &&
      item.attributes.confirmed_by_sales_rep === true
    ) {
      return "Confirmed";
    } else {
      return "Pending";
    }
  };

  const scrollToIndex = () => {
    let index;
    let newData = DateList.forEach((x: any, i: any) => {
      if (new Date(x.date).toDateString() === new Date().toDateString()) {
        index = i;
      }
    });
    flatlistRef.current.scrollToIndex({ animated: true, index: index });
  };

  const selectedDate = (date: any) => {
    let newList = DateList.map((x: any) => {
      if (x.date === date) {
        x.checked = true;
        getorders(Date.parse(date));
      } else {
        x.checked = false;
      }
      return x;
    });
    setDateList(newList);
  };

  const renderPatientName = (item: any) => {
    if (role_id === "4") {
      return hideName(item.attributes.first_name, item.attributes.last_name);
    } else {
      return item.attributes.first_name + " " + item.attributes.last_name;
    }
  };

  const renderImage = (item :any) => {
    if(item.attributes.patient_image){
     return item.attributes.patient_image
    } else {
      return handleCondition(item.attributes.patient_gender === "Male",require("../assets/patient_male.png"), require("../assets/patient_female.png") )
    }
  } 

  return (
    <View style={styles.container}>
      {popup && (
        <CustomPopUp
          message={message}
          ClosePopUp={() => setPopup(false)}
          btnText="Back"
        />
      )}
      <View style={styles.headingView}>
        <TouchableOpacity
          style={styles.leftArrowIconWrapper}
          onPress={() => navigation.goBack()}
        >
          <img
            src={require("../assets/leftArrow1.png")}
            style={{
              height: 24,
              width: 24,
            }}
          />
        </TouchableOpacity>
        <Text style={styles.heading}>All Bookings </Text>
      </View>
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          marginHorizontal: 24,
        }}
      >
        <View style={styles.searchBox}>
          <TextInput
            style={styles.input}
            placeholder="Search "
            autoCapitalize="none"
            autoCorrect={false}
            clearButtonMode="always"
            placeholderTextColor="#939ca3"
            value={query}
            onChangeText={(queryText) => handleSearch(queryText)}
          />
          <TouchableOpacity onPress={() => setQuery("")}>
            <img
              src={handleCondition(
                query === "",
                require("../assets/search.png"),
                require("../assets/cross.png")
              )}
              style={{
                height: 30,
                width: 30,
                paddingRight: 15,
                paddingTop: 10,
              }}
            />
          </TouchableOpacity>
        </View>
        <View style={styles.filterIcon}>
          <TouchableOpacity>
            <img
              src={require("../assets/filtericon.png")}
              style={{ height: 30, width: 30, paddingRight: 15 }}
            />
          </TouchableOpacity>
        </View>
      </View>

      <View style={styles.dates}>
        <FlatList
          ref={flatlistRef}
          data={DateList}
          horizontal={true}
          style={{ width: 380 }}
          showsHorizontalScrollIndicator={false}
          getItemLayout={getItemLayout}
          keyExtractor={(item: any) => item.date}
          renderItem={({ item }) => (
            <TouchableOpacity
              key={item.date}
              onPress={() => selectedDate(item.date)}
              style={handleCondition(
                item.checked,
                styles.dateBox_active,
                styles.dateBox
              )}
            >
              <Text
                style={handleCondition(
                  item.checked,
                  styles.dateDay_active,
                  styles.dateDay
                )}
              >
                {item.date.split(" ")[0].toUpperCase()}
              </Text>
              <Text
                style={handleCondition(
                  item.checked,
                  styles.dateNumber_active,
                  styles.dateNumber
                )}
              >
                {item.date.split(" ")[2]}
              </Text>
            </TouchableOpacity>
          )}
        />
      </View>

      <View
        style={{
          flexDirection: "row",
          justifyContent: "center",
          marginVertical: 10,
          marginHorizontal: 50,
        }}
      >
        {buttons.map((x: any) => {
          return (
            <TouchableOpacity onPress={() => showConfirmedBookings(x.name)}>
              <View
                style={handleCondition(
                  x.isSelected,
                  styles.button2,
                  styles.button
                )}
              >
                <Text
                  style={handleCondition(
                    x.isSelected,
                    styles.buttonText2,
                    styles.buttonText
                  )}
                >
                  {x.name}
                </Text>
              </View>
            </TouchableOpacity>
          );
        })}
      </View>

      {loader ? (
        <ActivityIndicator
          style={{ margin: "auto", height: 100, width: 100 }}
          size="large"
          color="#4e3e71"
        />
      ) : (
        <View style={{ flex: 1 }}>
          <ScrollView>
            <FlatList
              data={data}
              keyExtractor={(item: any) => item.id}
              ListEmptyComponent={ListEmptyView()}
              renderItem={({ item }: { item: any }) => (
                <TouchableOpacity onPress={() => goToNextPage(item)}>
                  <View style={{ marginVertical: 15 }}>
                    <View style={styles.bookingList}>
                      <View style={{ flexDirection: "row" }}>
                        <img
                          src={renderImage(item)}
                          style={{ height: 70, width: 70, borderRadius: 12 }}
                        />
                        <View
                          style={{
                            flexDirection: "column",
                            marginVertical: "auto",
                          }}
                        >
                          <Text numberOfLines={2} style={styles.cardHeading}>
                            {renderPatientName(item)}
                          </Text>
                          <Text style={styles.paraDis}>
                            {item.attributes.hospital.data.attributes.name}
                          </Text>
                        </View>
                      </View>
                      <View
                        style={{
                          flexDirection: "row",
                          justifyContent: "space-between",
                          paddingTop: 15,
                        }}
                      >
                        <Text style={{ color: "#b2bac6", fontSize: 12 }}>
                          Surgery Date & time:
                        </Text>
                        <Text style={{ color: "#5e6f88", fontSize: 12 }}>
                          {getBookingDate(item.attributes.booking_datetime)} at{" "}
                          {getBookingTime(item.attributes.booking_datetime)}
                        </Text>
                      </View>
                    </View>
                    <View style={styles.bookingList2}>
                      <Text style={styles.statusBTn}>
                        {BookingStatus(item)}
                      </Text>
                      <Text style={{ fontSize: 12, color: "#4e3e71" }}>
                        View Details
                      </Text>
                    </View>
                  </View>
                </TouchableOpacity>
              )}
            />
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-around",
                paddingBottom: 10,
              }}
            >
              {handleCondition(
                offset < 1,
                null,
                <TouchableOpacity onPress={() => setOffset(offset - 10)}>
                  <Text style={{ color: "#4e3e71", fontWeight: "bold" }}>
                    {"< Prev"}
                  </Text>
                </TouchableOpacity>
              )}
              {handleCondition(
                data.length < 10,
                null,
                <TouchableOpacity onPress={() => setOffset(offset + 10)}>
                  <Text style={{ color: "#4e3e71", fontWeight: "bold" }}>
                    {"Next >"}
                  </Text>
                </TouchableOpacity>
              )}
            </View>
          </ScrollView>
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#ffff",
    flexDirection: "column",
  },
  leftArrowIconWrapper: {
    width: 24,
    height: 24,
    position: "absolute",
    left: 24,
  },
  dateBox_active: {
    borderRadius: 12,
    marginHorizontal: 5,
    height: 58,
    width: 60,
    borderWidth: 2,
    backgroundColor: "#4e3e71",
    borderColor: "#f2f4f5",
    flexDirection: "column",
    justifyContent: "space-between",
    paddingVertical: 10,
    paddingHorizontal: 10,
  },
  dateBox: {
    borderRadius: 12,
    marginHorizontal: 5,
    height: 58,
    width: 60,
    borderWidth: 2,
    backgroundColor: "#ffff",
    borderColor: "#f2f4f5",
    flexDirection: "column",
    justifyContent: "space-between",
    paddingVertical: 10,
    paddingHorizontal: 10,
  },
  dateDay: { fontSize: 12, color: "#4e3e71", marginHorizontal: "auto" },
  dateDay_active: { fontSize: 12, color: "#fff", marginHorizontal: "auto" },
  dateNumber: { fontSize: 17, color: "#4e3e71", marginHorizontal: "auto" },
  dateNumber_active: { fontSize: 17, color: "#fff", marginHorizontal: "auto" },
  dates: {
    marginHorizontal: "auto",
  },
  heading2: {
    marginVertical: 100,
    marginHorizontal: "auto",
    color: "#b2bac6",
    fontSize: 24,
    fontWeight: "bold",
    flexDirection: "row",
    justifyContent: "center",
  },
  bookingList: {
    padding: 20,
    marginHorizontal: 20,
    borderColor: "#e5e5e5",
    borderWidth: 1,
    borderTopLeftRadius: 12,
    borderTopRightRadius: 12,
  },
  bookingList2: {
    paddingHorizontal: 20,
    paddingVertical: 15,
    marginHorizontal: 20,
    borderColor: "#e5e5e5",
    borderWidth: 1,
    borderBottomLeftRadius: 12,
    borderBottomRightRadius: 12,
    flexDirection: "row",
    justifyContent: "space-between",
  },
  statusBTn: {
    paddingVertical: 5,
    paddingHorizontal: 10,
    fontSize: 12,
    backgroundColor: "#4e3e71",
    color: "#fff",
    borderRadius: 12,
  },
  outerCard: {
    flexDirection: "row",
    marginTop: 20,
    borderRadius: 15,
    elevation: 3,
    backgroundColor: "#fff",
    marginHorizontal: 20,
    marginVertical: 6,
  },
  innerCard: {
    margin: 10,
    borderRadius: 15,
    elevation: 3,
    backgroundColor: "#eff8ff",
    padding: 25,
  },
  paraDis: {
    color: "#8e9aab",
    paddingHorizontal: 15,
    fontSize: 14,
  },
  para: {
    marginVertical: 35,
    color: "#0778df",
    marginLeft: 60,
  },
  cardHeading: {
    paddingHorizontal: 15,
    maxWidth: Scale(280),
    color: "#4e3e71",
    fontSize: 17,
  },
  searchBox: {
    flexDirection: "row",
    // marginHorizontal:26,
    marginVertical: 10,
    borderWidth: 1,
    borderColor: "#8a8a8a",
    borderRadius: 50,
  },
  input: {
    borderWidth: 0,
    flex: 1,
    paddingLeft: 10,
    width: 250,
    height: 48,
    color: "#4e3e71",
    fontSize: 16,
    paddingStart: 20,
  },
  searchBox2: {
    marginHorizontal: 20,
    borderRadius: 50,
    borderWidth: 2,
  },
  searchIcon: {
    paddingLeft: 10,
  },
  headingView: {
    flexDirection: "row",
    alignItems: "center",
    paddingVertical: 27,
    backgroundColor: "#4e3e71",
    marginBottom: 20,
  },
  heading: {
    color: "#e8fafe",
    fontSize: 20,
    marginHorizontal: "auto",
  },
  filterIcon: {
    height: 48,
    width: 48,
    borderRadius: 24,
    padding: 8,
    borderWidth: 1,
    borderColor: "#4e3e71",
    marginVertical: 10,
    marginHorizontal: 11,
  },
  button: {
    borderRadius: 5,
    paddingVertical: 10,
    paddingHorizontal: 15,
    marginHorizontal: 5,
    backgroundColor: "white",
  },
  buttonText: {
    color: "#4e3e71",
    fontSize: 15,
    textAlign: "center",
  },
  button3: {
    borderRadius: 5,
    paddingVertical: 5,
    paddingHorizontal: 5,
    marginHorizontal: 5,
    backgroundColor: "white",
  },
  buttonText3: {
    // color: '#0778df',
    fontSize: 15,
    textAlign: "center",
  },
  button2: {
    borderRadius: 5,
    paddingVertical: 10,
    paddingHorizontal: 15,
    marginHorizontal: 5,
    backgroundColor: "#4e3e71",
  },
  buttonText2: {
    color: "white",
    fontSize: 15,
    textAlign: "center",
  },
});
// Customizable Area End
