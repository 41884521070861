import React, { useState } from 'react';
import {
      StyleSheet,
      Text,
      View,
      TouchableOpacity,
      TextInput,
      Picker,
      ActivityIndicator
} from 'react-native';
import EditIcon from '@material-ui/icons/Edit';
import DateFnsUtils from '@date-io/date-fns';
import {
      MuiPickersUtilsProvider,
      KeyboardDatePicker
} from '@material-ui/pickers';
import { getErrorMessage, patchAxiosAPI, postAxiosAPI, putAxiosAPI } from '../../../framework/src/Blocks/AxiosAPIBlock';
import CustomPopUp from '../../../framework/src/CustomPopUp';
import { Formik } from "formik";
import * as yup from "yup";
import Dialog from '@material-ui/core/Dialog';
import ConfirmByOTP from "./ConfirmByOtp";
import GlobalCss from '../../../framework/src/GlobalCss';
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "./editProfile.css"
import { isValidPhoneNumber } from 'react-phone-number-input'
import { handleAndCondition } from '../../../framework/src/Utilities';


const validation_Schema = yup.object({
      fname: yup.string().required("First name is required").matches(/[a-zA-Z]/, "Only alphabets are allowed with minimum length 1"),
      lname: yup.string().required("Last name is required").matches(/[a-zA-Z]/, "Only alphabets are allowed with minimum length 1"),
      email: yup.string()
            .email("Please enter a valid Email")
            .required("Email is required"),
      full_phone_number: yup.string().required("Phone is required").test("phone-validation", "Invalid phone number", function (value) {
            return isValidPhoneNumber("+"+value);
          }),

})

export default function Profile({ navigation, parent, role }: { navigation: any, parent: any, role: any }) {


      const order = JSON.parse(localStorage.getItem('profileData') || '{}')
      const [image, setImage] = useState<any>(null)
      const [imageDemo, setImageDemo] = useState<any>(null)

      const [open, setOpen] = useState(false)

      const [message, setMessage] = useState("");
      const [popup, setPopup] = useState(false)
      const [loader, setLoader] = useState(false);

      const handleClose = () => {
            setOpen(false)
            localStorage.setItem("timer", '61');
      }

      const ClosePopUp = () => {
            if (window.screen.width < 950) {
                  navigation.goBack()
            }
            setPopup(false)
      }

      const handleDateChange = (date: any) => {
            setSelectedDate(date);
      };
      const onImageChange = (event: any) => {
            setImageDemo(URL.createObjectURL(event.target.files[0]));
            setImage(event.target.files[0])
      }

      const date = order?.attributes?.date_of_birth === 0 ? new Date() : order?.attributes?.date_of_birth
      let d = new Date(date);
      const [selectedDate, setSelectedDate] = useState(d.toLocaleDateString());

      function getChangedKeys(oldData: any, newData: any) {
            const changedKeys = [];

            for (const key in newData) {
                  if (newData.hasOwnProperty(key)) {
                        if (newData[key] !== oldData[key]) {
                              changedKeys.push(key);
                        }
                  }
            }

            return changedKeys;
      }

      const sendOtp = async (arr: any, value: any) => {
            const data = {
                  [arr[0]]: value[arr[0]]
            }
            const url = '/account_block/accounts/update_email_phone'
            await patchAxiosAPI(url, data).then((res: any) => {
                  if (res.error) {
                        let msg = getErrorMessage(res.data.errors)
                        setMessage(msg);
                        setPopup(true)
                        setLoader(false)
                  } else {
                        setLoader(false)
                        navigationTo(res.data)
                  }
            });
      }

      const UpdateProfile = async (value: any) => {
            const oldData = {
                  full_phone_number: order.attributes.full_phone_number,
                  email: order.attributes.email
            }
            const newData = {
                  full_phone_number: value.full_phone_number,
                  email: value.email
            }
            setLoader(true)
            const changedKeys = getChangedKeys(oldData, newData);
            if (changedKeys.length) {
                  if (changedKeys.length >= 2) {
                        setMessage("You can not change Email & Phone number togather.Try to change one after the other ")
                        setPopup(true)
                        setLoader(false)
                  } else {
                        sendOtp(changedKeys, value)
                  }
            } else {
                  let imageData;
                  if (image) {
                        imageData = new FormData()
                        imageData.append('full_phone_number', value.full_phone_number);
                        imageData.append('email', value.email);
                        imageData.append('profile_image', image ? image : null, image.name ? image.name : null);
                        imageData.append('first_name', value.fname.trim());
                        imageData.append('last_name', value.lname.trim());
                        imageData.append('address', value.address);
                        imageData.append('gender', value.gender,);
                        imageData.append('date_of_birth', Date.parse(selectedDate).toString());
                  } else {
                        imageData = new FormData()
                        imageData.append('full_phone_number', value.full_phone_number);
                        imageData.append('email', value.email);
                        imageData.append('first_name', value.fname.trim());
                        imageData.append('last_name', value.lname.trim());
                        imageData.append('address', value.address);
                        imageData.append('gender', value.gender,);
                        imageData.append('date_of_birth', Date.parse(selectedDate).toString());
                  }
                  let url = "/account_block/accounts/update_account";
                  await putAxiosAPI(url, imageData).then((res: any) => {
                        if (res.error) {
                              let msg = getErrorMessage(res.data.errors[0])
                              setPopup(true)
                              setMessage(msg)
                              setLoader(false)
                        } else {
                              setLoader(false)
                              navigationTo(res.data)
                        }
                  });
            }
      }





      const navigationTo = (res: any) => {
            if (res.email_otp) {
                  let data = {
                        otp: res.email_otp.data.attributes.pin,
                        sendedAt: res.email_otp.data.attributes.email,
                        token: res.email_otp.meta.token
                  }
                  localStorage.setItem('EditingProfileOtpData', JSON.stringify(data))
                  if (window.screen.width < 950) {
                        navigation.navigate('ConfirmByOtp')
                  } else {
                        setOpen(true)
                  }

            } else if (res.sms_otp) {
                  let data = {
                        otp: res.sms_otp.data?.attributes?.pin,
                        sendedAt: res.sms_otp?.data.attributes.full_phone_number,
                        token: res.sms_otp.meta.token
                  }
                  localStorage.setItem('EditingProfileOtpData', JSON.stringify(data))
                  if (window.screen.width < 950) {
                        navigation.navigate('ConfirmByOtp')
                  } else {
                        setOpen(true)
                  }
            } else {
                  setPopup(true)
                  setMessage("Profile Updated Successfully")
            }
      }


      return (

            <View style={{ flex: 1, flexDirection: 'column', justifyContent: 'space-between' }}>
                  {popup && <CustomPopUp ClosePopUp={ClosePopUp} btnText={"Back"} message={message} />}
                  <View style={styles.bottomContainer}>
                        <View style={styles.container}>
                              {window.screen.width < 950 ? <View style={styles.headingView}>
                                    <TouchableOpacity style={styles.leftArrowIconWrapper} onPress={() => navigation.goBack()}>
                                          <img src={require("../assets/leftArrow1.png")} style={{
                                                height: 24, width: 24,
                                          }} />
                                    </TouchableOpacity>
                                    <Text style={styles.heading}>Edit Profile</Text>
                              </View> : null}

                              <View style={styles.profileImage} >
                                    {
                                          imageDemo ?
                                                <img src={imageDemo} alt="preview image" style={{ width: 110, height: 110, borderRadius: '50%', }} /> :
                                                <img src={order?.attributes?.profile_image ? order.attributes.profile_image : require("../assets/avatar-chat1.png")} style={{ height: 110, width: 110, paddingTop: 10, borderRadius: '50%' }} />
                                    }

                              </View>

                              <label htmlFor="file">

                                    <View style={window.screen.width < 950 ? styles.editIcon : styles.editIconWeb}>
                                          <TouchableOpacity>
                                                <EditIcon style={{ fontSize: 25 }} />
                                          </TouchableOpacity>
                                    </View>

                              </label>


                              <input id="file" type="file" accept="image/*" style={{ display: 'none' }} onChange={onImageChange} />


                        </View>
                        <Formik
                              initialValues={{
                                    fname: order.attributes.first_name ? order.attributes.first_name : '',
                                    lname: order.attributes.last_name ? order.attributes.last_name : '',
                                    email: order.attributes.email ? order.attributes.email : '',
                                    date_of_birth: order.attributes.date_of_birth ? order.attributes.date_of_birth : d.toLocaleDateString(),
                                    address: order.attributes.address ? order.attributes.address : '',
                                    gender: order.attributes.gender ? order.attributes.gender : '',
                                    full_phone_number: order.attributes.full_phone_number ? order.attributes.full_phone_number : '',
                              }}
                              validationSchema={validation_Schema}
                              onSubmit={ (values) => {
                                          UpdateProfile(values)
                              }}
                        >
                              {
                                    ({ errors, touched, handleChange, handleBlur, values, handleSubmit }) => (
                                          <View style={styles.list}>
                                                <Text style={styles.inputlabel}>First Name </Text>
                                                <TextInput
                                                      style={styles.input}
                                                      placeholder="First Name"
                                                      onBlur={handleBlur('fname')}
                                                      placeholderTextColor="#b2bac6"
                                                      onChangeText={handleChange('fname')}
                                                      value={values.fname}
                                                />
                                                {handleAndCondition(touched.fname ,errors.fname , <Text style={styles.errMsg} >{errors.fname}</Text> , null)}
                                                <Text style={styles.inputlabel}>Last Name</Text>
                                                <TextInput
                                                      style={styles.input}
                                                      placeholder="Last Name"
                                                      onBlur={handleBlur('lname')}
                                                      placeholderTextColor="#b2bac6"
                                                      onChangeText={handleChange('lname')}
                                                      value={values.lname}
                                                />
                                                {handleAndCondition(touched.lname, errors.lname ,<Text style={styles.errMsg} >{errors.lname}</Text> ,null)}
                                                <Text style={styles.inputlabel}>Date of Birth</Text>
                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                      <KeyboardDatePicker
                                                            style={{
                                                                  fontSize: 18,
                                                                  paddingTop: 10,
                                                                  paddingBottom: 10
                                                            }}
                                                            placeholder="MM/DD/YYYY"
                                                            value={selectedDate}
                                                            onChange={handleDateChange}
                                                            format="MM/dd/yyyy"
                                                      />
                                                </MuiPickersUtilsProvider>
                                                {handleAndCondition(touched.date_of_birth,errors.date_of_birth, <Text style={styles.errMsg} >{errors.date_of_birth}</Text>,null)}
                                                <Text style={styles.inputlabel}>Phone Number</Text>
                                                <View style={{ zIndex: 1 }}>
                                                      <PhoneInput
                                                            countryCodeEditable={true}
                                                            country={"in"}
                                                            buttonClass="flagsdropdown"
                                                            containerClass="PhoneInput customPhnInput"
                                                            inputClass="PhoneInputInput customPhnInput2"
                                                            placeholder="Enter phone number"
                                                            value={values.full_phone_number}
                                                            onChange={handleChange("full_phone_number")}
                                                            onBlur={handleBlur("full_phone_number")}
                                                      />
                                                      {handleAndCondition(touched.full_phone_number ,errors.full_phone_number ,(
                                                            <Text style={styles.errMsg}>{errors.full_phone_number}</Text>
                                                      ) , null)}
                                                </View>
                                                <Text style={styles.inputlabel}>Address</Text>
                                                <TextInput
                                                      style={styles.input}
                                                      multiline
                                                      placeholder="Address"
                                                      onBlur={handleBlur('address')}
                                                      placeholderTextColor="#b2bac6"
                                                      onChangeText={handleChange('address')}
                                                      value={values.address}
                                                />
                                                {handleAndCondition(touched.address ,errors.address , <Text style={styles.errMsg} >{errors.address}</Text> , null)}

                                                <Text style={styles.inputlabel}>Email</Text>
                                                <TextInput
                                                      style={styles.input}
                                                      placeholder="Email"
                                                      onBlur={handleBlur('email')}
                                                      placeholderTextColor="#b2bac6"
                                                      onChangeText={handleChange('email')}
                                                      value={values.email}
                                                />
                                                {handleAndCondition(touched.email,errors.email ,<Text style={styles.errMsg} >{errors.email}</Text> , null)}
                                                <Text style={styles.inputlabel}>Gender</Text>
                                                <View style={styles.pickerContainer}>
                                                      <Picker
                                                            selectedValue={values.gender}
                                                            style={styles.picker}
                                                            onValueChange={handleChange('gender')}
                                                      >
                                                            <Picker.Item value={null} label='Select gender' />
                                                            <Picker.Item label="Male" value="Male" />
                                                            <Picker.Item label="Female " value="Female" />
                                                            <Picker.Item label="Other" value="Other" />

                                                      </Picker>
                                                      {role === '2' || role === '3' ?
                                                            <View>
                                                                  <Text style={styles.inputlabel}>Contact Priority </Text>
                                                                  <Text style={styles.input}>{order?.attributes?.is_primary_contact ? "Primary" : "Secondary"}</Text>
                                                            </View> : null}
                                                </View>
                                                <TouchableOpacity disabled={loader ? true : false} onPress={() => handleSubmit()}>
                                                      <View style={loader ? GlobalCss.buttonViewDisable : GlobalCss.buttonView}>
                                                            <Text style={GlobalCss.buttonText}>Save changes</Text>
                                                            {loader && <ActivityIndicator style={GlobalCss.loader} size="large" color="#2e2442" />}
                                                      </View>
                                                </TouchableOpacity>
                                          </View>

                                    )
                              }

                        </Formik>
                  </View>
                  <Dialog
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                  >
                        <ConfirmByOTP handleClose={handleClose} navigation={navigation} />
                  </Dialog>
            </View>
      )

}
const styles = StyleSheet.create({
      errMsg:{
             color: 'red', padding: 10 
      },
      container: {
            flex: 1,
            backgroundColor: "#fff"
      },
      headingView: {
            flexDirection: 'row',
            alignItems: 'center',
            paddingVertical: 15,
            backgroundColor: '#4e3e71',
            marginBottom: 20,

      },
      loader: { position: 'absolute', left: 0, right: 0, bottom: 6 },
      leftArrowIconWrapper: {
            width: 24,
            height: 24,
            position: "absolute",
            left: 24
      },
      title: {
            color: '#4e3e71',
            fontSize: 18,
            paddingVertical: 5,
            fontWeight: 'bold'
      },
      body: {
            color: '#4e3e71',
            fontSize: 18,
            paddingVertical: 5,
            borderBottomColor: '#e5e5e5',
            borderBottomWidth: 1,
            paddingBottom: 20
      },
      heading: {
            color: '#e8fafe',
            fontSize: 20,
            marginHorizontal: 'auto'
      },
      inputlabel: {
            color: '#4e3e71',
            fontSize: 18,
            paddingVertical: 5,
            fontWeight: 'bold'
      },
      pickerContainer: {
            borderBottomWidth: 1,
            borderBottomColor: '#e5e5e5',
            paddingVertical: 10
      },
      picker: {
            width: '100%',
            height: 50,
            color: '#4e3e71',
            borderRadius: 5,
            borderWidth: 1,
            borderColor: 'white'
      },
      input: {
            borderWidth: 0,
            flex: 1,
            marginVertical: 10,
            paddingVertical: 15,
            paddingLeft: 10,
            fontSize: 18,
            color: '#112950',
            borderBottomWidth: 1,
            borderBottomColor: '#e5e5e5'
      },
      button: {
            borderRadius: 12,
            paddingVertical: 15,
            paddingHorizontal: 50,
            marginHorizontal: 20,
            backgroundColor: '#4e3e71',
            marginBottom: 38,
            marginTop: 10
      },
      buttonText: {
            color: '#e8fafe',
            fontSize: 17,
            textAlign: 'center',
      },
      profileImage: {
            flexDirection: 'row',
            marginHorizontal: 20,
            position: 'relative'
      },
      editIconWeb: {
            justifyContent: 'center',
            position: 'absolute',
            top: 95,
            left: 102,
            elevation: 10,
            backgroundColor: '#e9e9e9',
            borderRadius: 5
      },
      editIcon: {
            justifyContent: 'center',
            position: 'absolute',
            top: 155,
            left: 102,
            elevation: 10,
            backgroundColor: '#e9e9e9',
            borderRadius: 5
      },
      bottomContainer: {
      },
      list: {
            flexDirection: 'column',
            marginHorizontal: 20,
            marginVertical: 20,
            justifyContent: 'center'
      },
      text: {
            padding: 18,
            backgroundColor: '#f1f1f1',
            borderRadius: 5,
            margin: 8,
            flexDirection: 'row',
            justifyContent: 'space-between'
      },
      icons: {
            justifyContent: 'flex-end',
      }
})    