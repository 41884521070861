import React, { useState, useEffect } from 'react';
import {
      StyleSheet,
      Text,
      View,
      TouchableOpacity,
      TextInput,
      Button,
      Image,
      ScrollView,
      FlatList,
} from 'react-native';
import * as IMG_CONST from './assets'
import scale, { verticalScale } from "../../../components/src/Scale";
import { getAxiosAPI, getErrorMessage, patchAxiosAPI } from '../../../framework/src/Blocks/AxiosAPIBlock';
import { ageWithMonth } from '../../../framework/src/Utilities';
import CustomPopUp from '../../../framework/src/CustomPopUp';



export default function NotiFication({ navigation , handleParent}: { navigation: any, handleParent : any }) {

      const [data, setData] = useState<any>({});
      const [suregryDetails, getSergeryDetails] = useState<any>({});
      const id = localStorage.getItem("notificationId")
      const [popUp, setPopUp] = useState(false);
      const [message, setMessage] = useState("");


      const getSurgeryDetail = async (id: any) => {
            let url = `/bx_block_order/orders/${id}`
            await getAxiosAPI(url).then((res: any) => {
                  if(res.error){
                    let msg = getErrorMessage(res.data.errors[0])
                  } else {
                        getSergeryDetails(res.data.data)                  }
                })

      }
      var oldDate = new Date(suregryDetails?.attributes?.booking_datetime);
      var newDate = new Date(data.attributes?.proposed_date)

      useEffect(() => {
            const getNotifications = async () => {
                  let url = `/bx_block_order/orders/propose_date_request?id=${id}`
                  await getAxiosAPI(url).then((res: any) => {
                        if(!res.error){
                              setData(res.data.data)
                              getSurgeryDetail(res.data.data.attributes.order_id) 
                        }
                      })
            }
            getNotifications()

      }, []);
      
      const callApi = async (status: any) => {
            const postData = {
                  order_propose_date_request_id: data.id,
                  status: status
            }
            let url = `/bx_block_order/orders/process_propose_date_request`;
            await patchAxiosAPI(url, postData).then((res: any) => {
              if(res.error){
                let msg = getErrorMessage(res.data.errors[0])
                    setMessage(msg)
                    setPopUp(true)
              } else {
                  setMessage(status+" successfully ")
                  setPopUp(true)         
              }
            })
      }

      const handleClose = () => {
            
            if(window.screen.width < 950){
                  navigation.goBack()
            }else {
                  handleParent()
            }
      }

      return (
            <View style={styles.container}>
                  {popUp && <CustomPopUp  message={message} btnText="Back" ClosePopUp={handleClose}/> }
                  {window.screen.width < 950 && <View style={styles.renderHaderContainer}>
                        <TouchableOpacity onPress={() => navigation.goBack()}>
                              <Image source={IMG_CONST.leftArrow} style={styles.leftArrowImg} />
                        </TouchableOpacity>
                        <Text style={styles.renderHaderTextContainer}>Notification </Text>
                        <Image source={IMG_CONST.setting} style={styles.settingImg} />
                  </View>}

                  <View style={styles.card}>

                        <View style={styles.profileDetails}>
                              <img src={require("../assets/patient.png")} style={{ height: 66, width: 66 }} />
                              <View style={styles.names}>
                                    <Text style={styles.info}>Patient Name: {suregryDetails?.attributes?.first_name+" "+suregryDetails?.attributes?.last_name} </Text>
                                    <Text style={styles.info}>Patient Age: {ageWithMonth(suregryDetails?.attributes?.patient_age)}</Text> 
                              </View>
                             
                        </View>
                        <View>
                              <Text style={styles.headingNew}>Booking Information</Text>
                              <Text style={styles.infoNew}>The booking coordinator has proposed a new booking date and time from   <b>{oldDate.toLocaleDateString()}</b> at <b>{oldDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</b> to 
                                    <b> {newDate.toLocaleDateString()}</b> at <b>{newDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</b>
                              </Text>
                              <View style={styles.buttonsView}>
                    
                              <TouchableOpacity onPress={() => callApi("accepted")}>
                                    <View style={styles.button}>
                                          <Text style={styles.buttonText}>Accept</Text>
                                    </View>
                              </TouchableOpacity>
                              <TouchableOpacity onPress={() => callApi("rejected")}>
                                    <View style={styles.button1}>
                                          <Text style={styles.buttonText1}>Reject</Text>
                                    </View>
                                    </TouchableOpacity>

                              </View>
                        </View>
                  </View>
            </View>
      )
}



const styles = StyleSheet.create({
      container: {
            flex: 1,
            backgroundColor: "#ffff",
            flexDirection: 'column'
      },
      buttonsView: {
            marginVertical: 10,
            flexDirection: 'row',
            justifyContent: 'space-between',
        marginHorizontal: 5
      },
      button1: {
            borderRadius: 12,
            paddingVertical: 15,
            paddingHorizontal: 40,
            marginHorizontal: 5,
            backgroundColor: '#fff',
            borderColor: '#4e3e71',
            borderWidth: 1,
            marginBottom: 10,
            marginTop: 10
      },
      buttonText1: {
            color: '#4e3e71',
            fontSize: 17,
            textAlign: 'center',
      },
      button: {
            borderRadius: 12,
            paddingVertical: 15,
            paddingHorizontal: 40,
            marginHorizontal: 5,
            backgroundColor: '#4e3e71',
            marginBottom: 10,
            marginTop: 10
      },
      buttonText: {
            color: '#e8fafe',
            fontSize: 17,
            textAlign: 'center',
      },
      headingNew: {
            fontSize: 20,
            fontWeight: 'bold',
            color: '#4e3e71'
      },
      card: {
            flexDirection: 'column',
            marginTop: 40,
            paddingHorizontal: 10,
            paddingVertical: 10,
            borderRadius: 11,
            marginHorizontal: 24,
            marginVertical: 6,
            borderWidth: 1,
            borderColor: '#4e3e71'
      },
      names: {
            paddingHorizontal: 16
      },
      profileDetails: {
            flexDirection: 'row',
            paddingHorizontal: 10,
            paddingVertical: 10,
            marginBottom: 20
      },
      info: {
            color: '#4e3e71',
            fontSize: 16,
            padding: 2,
      },
      infoNew: {
            color: '#4e3e71',
            fontSize: 16,
            paddingVertical: 5,
      },
      renderHaderContainer: {
            height: scale(72),
            backgroundColor: "#4e3e71",
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: "center",
            paddingLeft: scale(24),
            paddingRight: scale(24)
      },
      datebox: {
            color: 'white',
            backgroundColor: '#4e3e71',
            paddingHorizontal: 10,
            paddingVertical: 5,
            maxWidth: 100,
            justifyContent: 'center'
      },

      leftArrowImg: {
            width: scale(24),
            height: scale(24),
            resizeMode: 'contain'
      },

      renderHaderTextContainer: {
            color: "#e8fafe",
            fontSize: scale(20),
      },

      settingImg: {
            width: scale(24),
            height: scale(24)
      },

      renderProfileContainer: {
            paddingLeft: scale(24),
            marginTop: verticalScale(10)
      },

      renderProfileSubContainer: {
            width: scale(62),
            height: scale(24),
            backgroundColor: "#4e3e71",
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: scale(3)
      },

      renderProfileSubTextContainer: {
            color: "#ffffff",
            fontSize: scale(14.2)
      },

      renderProfileSubProfileContainer: {
            flexDirection: 'row',
            marginTop: verticalScale(27)
      },

      personImg: {
            width: scale(56),
            height: scale(56),
            resizeMode: "contain"
      },

      agoText: {
            color: '#112950',
            fontSize: scale(12),
            lineHeight: scale(18),
            marginTop: verticalScale(4)
      },

      lineView: {
            width: scale(327),
            height: scale(1),
            backgroundColor: "#f2f4f5",
            marginTop: verticalScale(21),
            alignSelf: 'center',
            marginRight: scale(20)
      },
      heading: {
            fontSize: 18,
            color: "#4e3e71",
            fontWeight: 'bold'
      },
      addedText: {
            color: "#8e9aab",
            lineHeight: scale(24),
            fontSize: scale(18),
            width: scale(320)
      },

      textContainer: {
            marginTop: verticalScale(15),
            marginLeft: scale(12)
      }

})

