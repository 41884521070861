// Customizable Area Start
import React, { useState, useEffect } from "react";
import { StyleSheet, Text, TouchableOpacity, Picker, View } from "react-native";
import DesktopHeader from "./DesktopHeader";
import DesktopMenue from "./DesktopMenue";
import scale from "../../../framework/src/Scale";
import { Formik } from "formik";
import * as yup from "yup";
import Scale from "../../../framework/src/Scale";
import { getAxiosAPI } from "../../../framework/src/Blocks/AxiosAPIBlock";

const validation_Schema = yup.object({
  size: yup.string().required("Size is required"),
});

const dummyDescription = "No description available";

export default function DesktopSurgeryDetails({
  navigation,
}: {
  navigation: any;
}) {
  const [salesRep, setSalesRep] = useState<any>(null);
  const [assignedTo, setAssignedTo] = useState("Facility");

  const order = JSON.parse(localStorage.getItem("order") || "{}");

  const assignToarray = [
    {
      name: "Sales Rep",
      status: false,
    },
    {
      name: "Facility",
      status: true,
    },
  ];
  const [assign, setAssign] = useState<any>([]);
  useEffect(() => {
    setAssign(assignToarray);
  }, []);

  const getSalesRep = async () => {
    let url = `/bx_block_order/orders/sales_representative_list?brand_id=${
      order.manfacturer_id
    }&category_id=${order.category_id}`;
    await getAxiosAPI(url).then((res: any) => {
      if (!res.error) {
        setSalesRep(res.data.data[0]);
      }
    });
  };

  useEffect(() => {
    getSalesRep();
  }, []);

  const [count, setCount] = useState(1);

  const incCont = () => {
    if (count < 10) {
      setCount(count + 1);
    }
  };
  const decCont = () => {
    if (count > 1) {
      setCount(count - 1);
    }
  };

  const material = JSON.parse(localStorage.getItem("selectedMaterial") || "{}");

  const addMaterialToList = (values: any) => {
    let selectedMaterial = {
      material_name: material.attributes.name,
      assign_to: assignedTo,
      material_id: material.id,
      size: values.size,
      assign_to_type: "",
      assign_to_id: "",
      quantity: count,
      uniqueID: new Date().getTime(),
      image: material.attributes.image,
    };
    if (selectedMaterial.assign_to === "Facility") {
      selectedMaterial.assign_to_type = "BxBlockHospital::Hospital";
      selectedMaterial.assign_to_id = order.hospital_id;
    } else {
      selectedMaterial.assign_to_type = "AccountBlock::Account";
      selectedMaterial.assign_to_id = salesRep ? salesRep.id : "not presnet";
    }

    if (localStorage.getItem("isCptCodeFlow") === "yes") {
      let materialsList = JSON.parse(
        localStorage.getItem("selectedCodeList") || "[]"
      );
      // filter unique materials -----------------

      const filteredArr = materialsList.reduce((unique: any, o: any) => {
        if (!unique.some((obj: any) => obj.material_id === o.material_id)) {
          unique.push(o);
        }
        return unique;
      }, []);
      filteredArr.push(selectedMaterial);
      localStorage.setItem("selectedCodeList", JSON.stringify(filteredArr));
      navigation.navigate("CMFPB");
    } else {
      let materialsList = JSON.parse(
        localStorage.getItem("selectedMaterialList") || "[]"
      );
      // filter unique materials -----------------

      const filteredArr = materialsList.reduce((unique: any, o: any) => {
        if (!unique.some((obj: any) => obj.material_id === o.material_id)) {
          unique.push(o);
        }
        return unique;
      }, []);
      filteredArr.push(selectedMaterial);

      localStorage.setItem("selectedMaterialList", JSON.stringify(filteredArr));
      order.salesRep = salesRep
        ? salesRep.attributes.first_name + " " + salesRep.attributes.last_name
        : "Not Present";
      localStorage.setItem("order", JSON.stringify(order));
      navigation.navigate("CartList");
    }
  };

  const handleAssign = (item: any) => {
    let newdata: any = assign.map((x: any) => {
      if (x.name === item.name) {
        x.status = !x.status;
      } else {
        x.status = false;
      }
      return x;
    });
    setAssign(newdata);
    setAssignedTo(item.name);
  };

  return (
    <View style={styles.desktopContainer}>
      <DesktopHeader heading={"Material Details"} navigation={navigation} />
      <View style={styles.bottomDesktopCard}>
        <DesktopMenue navigation={navigation} />
        <View style={{ flex: 0.85 }}>
          <View style={styles.outerCard}>
            <Formik
              initialValues={{ assign_to: "", size: "", quantity: "" }}
              validationSchema={validation_Schema}
              onSubmit={(values) => {
                addMaterialToList(values);
              }}
            >
              {({
                errors,
                touched,
                handleChange,
                handleBlur,
                values,
                handleSubmit,
              }) => (
                <View style={{ flex: 1, flexDirection: "row" }}>
                  <View>
                    <img
                      src={
                        material.attributes.image
                          ? material.attributes.image
                          : require("../assets/dummyMaterial.png")
                      }
                      style={{
                        height: 350,
                        width: 350,
                        borderRadius: 12,
                        paddingTop: 10,
                      }}
                    />
                  </View>
                  <View
                    style={{
                      marginHorizontal: 24,
                      marginTop: 50,
                      flexDirection: "column",
                      justifyContent: "space-between",
                    }}
                  >
                    <Text
                      numberOfLines={2}
                      style={{
                        width: Scale(220),
                        fontSize: 30,
                        color: "#4e3e71",
                      }}
                    >
                      {material.attributes.name}
                    </Text>
                    <Text style={{ fontSize: 30, color: "#4e3e71" }}>
                      (Manufacturor :{" "}
                      {material.attributes.brand.data.attributes.name})
                    </Text>
                    <Text
                      style={{
                        fontSize: 16,
                        paddingVertical: 10,
                        color: "#4e3e71",
                        width: scale(220),
                      }}
                    >
                      {material.attributes.description
                        ? material.attributes.description
                        : dummyDescription}
                    </Text>
                    <View style={{ flex: 1, flexDirection: "row" }}>
                      <View style={styles.pickerContainer}>
                        <Picker
                          selectedValue={values.size}
                          style={styles.picker}
                          onValueChange={handleChange("size")}
                        >
                          <Picker.Item value={null} label="Choose Size" />
                          <Picker.Item label="10mm" value="10mm" />
                          <Picker.Item label="20mm" value="20mm" />
                          <Picker.Item label="30mm" value="30mm" />
                          <Picker.Item label="40mm" value="40mm" />
                          <Picker.Item label="50mm" value="50mm" />
                          <Picker.Item label="60mm" value="60mm" />
                          <Picker.Item label="70mm" value="70mm" />
                          <Picker.Item label="80mm" value="80mm" />
                          <Picker.Item label="90mm" value="90mm" />
                          <Picker.Item label="100mm" value="100mm" />
                        </Picker>
                        {touched.size && errors.size ? (
                          <Text style={{ color: "red", paddingLeft: 40 }}>
                            {errors.size}
                          </Text>
                        ) : null}
                      </View>

                      <View style={styles.quantity}>
                        <TouchableOpacity onPress={() => incCont()}>
                          <Text style={styles.btn}>+</Text>
                        </TouchableOpacity>

                        <Text style={styles.btn2}>
                          {count === 10 ? count : "0" + count}
                        </Text>

                        <TouchableOpacity onPress={() => decCont()}>
                          <Text style={styles.btn}>-</Text>
                        </TouchableOpacity>
                      </View>

                      <View style={styles.assignTo}>
                        {assign.map((x: any) => {
                          return (
                            <TouchableOpacity onPress={() => handleAssign(x)}>
                              <View
                                style={
                                  x.status
                                    ? styles.buttonNew2
                                    : styles.buttonNew
                                }
                              >
                                <Text
                                  style={
                                    x.status
                                      ? styles.buttonTextNew2
                                      : styles.buttonTextNew
                                  }
                                >
                                  {x.name}
                                </Text>
                              </View>
                            </TouchableOpacity>
                          );
                        })}
                      </View>
                    </View>

                    <View>
                      <TouchableOpacity onPress={() => handleSubmit()}>
                        <View style={styles.button}>
                          <Text style={styles.buttonText}>Add to List</Text>
                        </View>
                      </TouchableOpacity>
                    </View>
                  </View>
                </View>
              )}
            </Formik>
          </View>
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  desktopContainer: {
    flex: 1,
    flexDirection: "column",
  },
  assignTo: {
    flexDirection: "row",
    borderColor: "#e5e5e5",
    borderWidth: 1,
    borderRadius: 8,
    height: 50,
    marginVertical: 10,
    marginHorizontal: 20,
  },
  quantity: {
    flexDirection: "row",
    height: 50,
    // width: 108,
    // height: 32,
    borderRadius: 5,
    borderColor: "#e5e5e5",
    borderWidth: 1,
    marginVertical: 10,
    marginHorizontal: 10,
    justifyContent: "space-between",
  },
  btn: {
    fontSize: 30,
    color: "#4e3e71",
    paddingVertical: 2,
    paddingHorizontal: 10,
  },
  btn2: {
    fontSize: 20,
    color: "#4e3e71",
    padding: 2,
    backgroundColor: "#cce9e5",
    borderRadius: 4,
    margin: 5,
    paddingHorizontal: 10,
  },
  name: {
    color: "#4e3e71",
    paddingVertical: 5,
    fontSize: 16,
  },
  pickerContainer: {
    // borderBottomWidth: 1,
    // borderBottomColor: '#e5e5e5',
    paddingVertical: 10,
    marginHorizontal: 24,
    height: 200,
  },
  picker: {
    width: "100%",
    height: 50,
    color: "#4e3e71",
    backgroundColor: "rgb(249, 250, 251)",
    paddingHorizontal: 30,
    borderRadius: 5,
    borderWidth: 1,
    borderColor: "white",
  },
  listm: {
    overflow: "hidden",
    shadowColor: "#b2bac6",
    shadowRadius: 10,
    shadowOpacity: 1,
    borderRadius: 12,
    padding: 16,
    borderColor: "#f2f4f5",
    borderWidth: 1,
    marginHorizontal: 15,
    marginVertical: 10,
  },
  buttonNew2: {
    borderRadius: 8,
    paddingVertical: 10,
    paddingHorizontal: 20,
    height: 50,
    backgroundColor: "#4e3e71",
    // width: '20%',
  },
  buttonTextNew2: {
    color: "#fff",
    fontSize: 17,
    textAlign: "center",
  },
  buttonNew: {
    borderRadius: 8,
    height: 48,
    paddingVertical: 10,
    paddingHorizontal: 20,
    backgroundColor: "#fff",
    // width: '20%',
  },
  buttonTextNew: {
    color: "#4e3e71",
    fontSize: 17,
    textAlign: "center",
  },
  button: {
    borderRadius: 12,
    paddingVertical: 15,
    paddingHorizontal: 20,
    marginVertical: 10,
    backgroundColor: "#4e3e71",
    // width: '20%',
    marginHorizontal: 10,
  },
  buttonText: {
    color: "#e8fafe",
    fontSize: 17,
    textAlign: "center",
  },
  button0: {
    borderRadius: 12,
    paddingVertical: 15,
    paddingHorizontal: 20,
    marginVertical: 10,
    backgroundColor: "#aa9cc9",
    // width: '20%',
    marginHorizontal: 10,
  },
  buttonText0: {
    color: "#e8fafe",
    fontSize: 17,
    textAlign: "center",
  },
  listItem: {
    height: 88,
    width: 360,
    marginHorizontal: 16,
    borderColor: "#e5e5e5",
    borderWidth: 1,
    borderRadius: 12,
    marginVertical: 5,
    padding: 20,
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
  },
  listItem_active: {
    height: 88,
    width: 360,
    marginHorizontal: 16,
    borderColor: "#4e3e71",
    borderWidth: 1,
    borderRadius: 12,
    marginVertical: 5,
    padding: 20,
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
  },
  role: {
    color: "#b2bac6",
    paddingHorizontal: 11,
    width: scale(50),
  },
  names: {
    color: "#4e3e71",
    fontSize: 15,
    paddingHorizontal: 11,
    width: scale(60),
  },

  add: {
    flexDirection: "row",
    justifyContent: "flex-start",
  },
  resultText: {
    paddingVertical: 10,
    fontSize: 18,
  },
  card: {
    flex: 1,
    marginTop: 10,
    borderRadius: 6,
    elevation: 3,
    backgroundColor: "#fff",
    marginVertical: 6,
  },
  filterIcon: {
    height: 48,
    width: 48,
    borderRadius: 24,
    padding: 8,
    borderWidth: 1,
    borderColor: "#4e3e71",
    marginVertical: "auto",
    marginHorizontal: "auto",
  },
  bottomDesktopCard: {
    flex: 1,
    flexDirection: "row",
  },
  searchBox: {
    flexDirection: "row",
    marginVertical: 20,
    borderWidth: 1,
    borderColor: "#8a8a8a",
    borderRadius: 50,
  },
  input: {
    borderWidth: 0,
    flex: 1,
    paddingLeft: 10,
    height: 48,
    color: "#4e3e71",
    fontSize: 16,
    paddingStart: 20,
    width: 950,
  },
  outerCard: {
    flex: 1,
    elevation: 3,
    backgroundColor: "white",
    borderWidth: 1,
    borderRadius: 12,
    borderColor: "#7f7f7f",
    padding: 50,
    margin: 15,
    flexDirection: "column",
  },
});
// Customizable Area End
