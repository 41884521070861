// Customizable Area Start
import React, {useState,  useEffect} from 'react';
import {StyleSheet,
Text,
View,
TouchableOpacity,
TextInput,
FlatList,
  ActivityIndicator } from 'react-native';
import { getAxiosAPI, getErrorMessage } from '../../../framework/src/Blocks/AxiosAPIBlock';
import { getBookingDate, getBookingTime, hideName, SortData } from "../../../framework/src/Utilities";
import Scale from '../../../framework/src/Scale';
import CustomPopUp from '../../../framework/src/CustomPopUp';



export default function ExBooking({navigation}: {navigation: any}) {
      const [data, setData] = useState([]);
      const [query, setQuery] = useState("");
      const [loader, setLoader] = useState(true);
      const [status, setStatus] = useState('confirmed')

  
  const [message, setMessage] = useState("");
  const [popup, setPopup] = useState(false)
  const [offset, setOffset] = useState(0);


  const getorders = async ( search: any = '') => {
    setLoader(true)
    let url = `/bx_block_order/orders?status=${status}&search=${search}&limit=5&offset=${offset}`
    await getAxiosAPI(url).then((res: any) => {
          if(res.error){
            let msg = getErrorMessage(res.data.errors[0])
            setPopup(true)
            setMessage(msg)
            setLoader(false)
          } else {
            setLoader(false)
            setData(res.data.data.sort((a: any, b: any) => SortData(a.attributes.booking_datetime, b.attributes.booking_datetime)))
          }
        })
  }
  
      useEffect(() => { 
            getorders()
          }, [offset, status]);

          const goToNextPage = (item: any) => {
            localStorage.setItem('previous_booking', JSON.stringify(item));
            navigation.navigate('SurgeryDetails')
          } 

          const handleSearch = (text : any) => {
            setQuery(text)
            getorders(text)
          };

          const [buttons, setButtons] = useState([
            {isSelected: true , name: 'Confirmed'},
            {isSelected: false, name: 'Pending'}
          ])
          
          const showConfirmedBookings = (name: any) => {
            if (name === 'Confirmed') {
              setButtons([
                { isSelected: true, name: 'Confirmed' },
                { isSelected: false, name: 'Pending' }
              ])
              setStatus('confirmed')
            } else {
              setButtons([
                { isSelected: false, name: 'Confirmed' },
                { isSelected: true, name: 'Pending' }
              ])
              setStatus('pending')
             }
            }
            
          

          const ListEmptyView = () => {
            return (
              <View>
                <Text style={styles.heading2}>No Booking Found</Text>
                </View>

            )
  }
      
          const role_id = JSON.parse(localStorage.getItem('role') || '{}')

  
      return (
            <View style={styles.container}>
              {popup && <CustomPopUp message={message} ClosePopUp={() => setPopup(false)} btnText="Back"/>}
      <View style={{flexDirection: 'row',paddingHorizontal:15, justifyContent: 'space-between'}}>
      <View style={styles.searchBox}>
                <TextInput
                          style={styles.input}
                          placeholder="Search "
                          autoCapitalize="none"
                          autoCorrect={false}
                          clearButtonMode="always"
                          placeholderTextColor="#939ca3"
                          value={query}
                          onChangeText={queryText => handleSearch(queryText)}
                  />
                <TouchableOpacity onPress={() => setQuery('')}>
              <img src={query === '' ? require("../assets/search.png" ) : require("../assets/cross.png" )} style={{height: 25, width: 25, paddingRight: 15, paddingTop: 8}}/>
              </TouchableOpacity> 
              </View> 
              {/* <View style={styles.filterIcon}>
                <TouchableOpacity>
                  <img src={require("../assets/filtericon.png" )} style={{height: 30, width: 30, paddingRight: 15}}/>
                </TouchableOpacity>
              </View> */}
              </View>
                  <View style={{flexDirection: 'row', justifyContent: 'center', marginVertical: 10, marginHorizontal: 50}}>
                        
                        {
                          buttons.map((x:any) => {
                            return (
                              <TouchableOpacity onPress={() => showConfirmedBookings(x.name)}>
                                <View style={x.isSelected ? styles.button2 : styles.button}>
                                  <Text style={x.isSelected ? styles.buttonText2 : styles.buttonText}>{x.name}</Text>
                                </View>
                              </TouchableOpacity>
                            )
                          })
                        }
                  </View>

          <View style={{
            flex: 1,
            height: 350,
            }}>
            {loader ?
              <ActivityIndicator
                style={{ margin: 'auto', height: 100, width: 100, }}
                size="large"
                color="#4e3e71"
              />
              : < View style={{ flex: 1, maxHeight: 420 }}>
                        <FlatList
                              data={data}
                              keyExtractor={(item:any) => item.id}
                              ListEmptyComponent={ListEmptyView()}
                              renderItem={({ item }: {item: any}) => (
                                <TouchableOpacity onPress={() => goToNextPage(item)}>
                               <View style={{marginVertical: 12}}>
                                        <View style={styles.bookingList}>
                                            <View style={{flexDirection: 'row'}}>
                                                <img src={item.attributes.patient_image ? item.attributes.patient_image : item.attributes.patient_gender === 'Male' ?  require("../assets/patient_male.png") :require("../assets/patient_female.png") }
                                                style={{height: 40, width: 40, borderRadius: 20}} />
                                                <View style={{flexDirection: 'column', marginVertical: 'auto'}}>
                                                    <Text numberOfLines={2} style={styles.cardHeading}>{role_id == '4' ? hideName(item.attributes?.first_name, item.attributes?.last_name) : item.attributes?.first_name+" "+item.attributes?.last_name} </Text>
                                                    <Text style={styles.paraDis}>{item.attributes.hospital.data?.attributes.name}</Text>
                                                </View>
                                                </View>
                                          <Text style={{color: '#5e6f88', fontSize: 12, marginVertical: 'auto', fontWeight: 'bold'}}>{getBookingDate(item.attributes.booking_datetime)} at {getBookingTime(item.attributes.booking_datetime)}</Text>
                                        </View>
                                             
                                      </View>
                                      </TouchableOpacity>
                              )}
                              />
                        </  View>}
                 
                       
                 
                  </View>
                  <View style={{flexDirection: 'row', justifyContent: 'space-around'}}>
                        {offset < 1 ? null : <TouchableOpacity onPress={() => setOffset(offset-5)}><Text style={{ color: '#4e3e71', fontWeight: "bold"}}>{"< Prev"}</Text></TouchableOpacity>}
                        {data.length < 5 ? null :  <TouchableOpacity onPress={() => setOffset(offset+5)}><Text style={{ color: '#4e3e71', fontWeight: "bold"}}>{"Next >"}</Text></TouchableOpacity>}
                      </View>

            </View>
      )
}

const styles = StyleSheet.create({
      container: {
            flex: 1,
            backgroundColor: "#ffff",
            flexDirection: 'column'
          },
          dates:{
            flexDirection: 'row',
            marginHorizontal: 'auto',
          }, 
          heading2: {
            marginVertical: 100,
            marginHorizontal: 'auto',
            color: '#b2bac6',
            fontSize: 24,
            fontWeight: 'bold',
            flexDirection : 'row',
            justifyContent: 'center'
          },
          bookingList: {
            paddingBottom: 10,
            // backgroundColor: 'pink',
            marginHorizontal: 10,
            borderColor: '#e5e5e5',
                borderBottomWidth: 1,
                flexDirection: 'row',
           justifyContent: 'space-between'
      //     borderRadius: 12
          },
          bookingList2: {
            paddingHorizontal: 20,
            paddingVertical: 15,
            marginHorizontal: 20,
            borderColor: '#e5e5e5',
           borderWidth: 1,
           borderBottomLeftRadius: 12,
           borderBottomRightRadius: 12,
           flexDirection: 'row',
           justifyContent: 'space-between'
          },
          statusBTn:{
            paddingVertical: 5, 
            paddingHorizontal: 10,
            fontSize: 12,
            backgroundColor: '#4e3e71', 
            color: '#fff', 
            borderRadius: 12
          },
          outerCard : {
            flexDirection: 'row',
            marginTop:20,
            borderRadius: 15,
            elevation: 3,
            backgroundColor: '#fff',
            marginHorizontal: 20,
            marginVertical: 6,
          },
          innerCard: {
            margin:10,
            borderRadius: 15,
            elevation: 3,
            backgroundColor: "#eff8ff",
            padding: 25,

          },
          paraDis: {   
            color: '#8e9aab',
            paddingHorizontal: 15,
            fontSize: 14
          },
          para: {
            marginVertical: 35,    
            color: '#0778df',
            marginLeft: 60,
          },
          cardHeading: {
            paddingHorizontal: 15,
            maxWidth: Scale(60),
            color: '#4e3e71',
            fontSize: 17,
        },      
      searchBox: {
        flex: 1,
        flexDirection: 'row',
        // marginHorizontal:26,
        marginVertical: 10,
        borderWidth: 1,
        borderColor: '#8a8a8a',
        borderRadius: 50
        
      },
          input: {
          borderWidth: 0,
            flex: 1,
        paddingLeft: 10,
        // width: 250,
        height: 40,
        color: '#4e3e71',
        fontSize: 16,
        paddingStart: 20
          },
      searchBox2: {
            marginHorizontal: 20,
            borderRadius: 50,
            borderWidth: 2,
      },
          searchIcon: {
            paddingLeft: 10
          }, 
          headingView: {
            flexDirection: 'row',
            alignItems: 'center',
            paddingVertical: 27,
            backgroundColor: '#4e3e71',
            marginBottom: 20,
        
          },
          heading:{
            color: '#e8fafe',
            fontSize: 20,
            marginHorizontal: 'auto'       
          },
          filterIcon: {
            height: 48,
            width: 48,
            borderRadius: 24,
            padding: 8,
            borderWidth: 1,
            borderColor: '#4e3e71',
            marginVertical: 10,
            marginHorizontal: 11
          },
          button: {
            borderRadius: 5,
            paddingVertical: 10,
            paddingHorizontal: 15,
            marginHorizontal: 5,
            backgroundColor: 'white',
          },
          buttonText: {
            color: '#0778df',
            fontSize: 15,
            textAlign: 'center',
          },
          button3: {
            borderRadius: 5,
            paddingVertical: 5,
            paddingHorizontal: 5,
            marginHorizontal: 5,
            backgroundColor: 'white',
          },
          buttonText3: {
            // color: '#0778df',
            fontSize: 15,
            textAlign: 'center',
          },
        button2: {
          borderRadius: 5,
          paddingVertical: 8,
          paddingHorizontal: 15,
          marginHorizontal: 5,
          backgroundColor: '#4e3e71',
        },
        buttonText2: {
          color: 'white',
          fontSize: 15,
          textAlign: 'center',
      },
         
})
// Customizable Area End
