import React, { useState, useEffect } from "react";
// Customizable Area Start
import {
      StyleSheet,
      Text,
      TouchableOpacity,
      TextInput,
      View,
      FlatList,
      ActivityIndicator
} from "react-native";
import DesktopHeader from "./DesktopHeader";
import DesktopMenue from "./DesktopMenue";
import { getAxiosAPI, getErrorMessage } from "../../../framework/src/Blocks/AxiosAPIBlock";
import { getBookingDate, getBookingTime } from "../../../framework/src/Utilities";


export default function DesktopSurgeryDetails({ navigation }: { navigation: any }) {
      const [data, setData] = useState([]);
      const [query, setQuery] = useState("");
      const [loader, setLoader] = useState(false);
      const [offset, setOffset] = useState(0);

      const getorders = async (query: any = "") => {
            setLoader(true)
            let url = `/bx_block_order/orders?&search=${query}&limit=12&offset=${offset}`
            await getAxiosAPI(url).then((res: any) => {
                  if(res.error){
                    let msg = getErrorMessage(res.data.errors[0])
                    setLoader(false)
                  } else {
                        setData(res.data.data.filter((x: any) => x.attributes.booking_datetime < new Date().getTime().toString()))
                        setLoader(false)
                  }
                })
            }

      useEffect(() => {
            getorders(query)
      }, [query, offset]);

      const goToNextPage = (item: any) => {
            localStorage.setItem('previous_booking', JSON.stringify(item));
            navigation.navigate('SurgeryDetails')
      }

      const handleSearch = (text: any) => {
            setQuery(text)
      };






      const ListEmptyView = () => {
            return (
                  <View>
                        <Text style={styles.heading}>No Booking Found</Text>
                  </View>

            )
      }

      const BookingStatus = (item: any) => {
            if (item.attributes.confirmed_by_bc === true &&
                  item.attributes.confirmed_by_material_manager === true &&
                  item.attributes.confirmed_by_sales_rep === true) {
                  return "Confirmed"
            } else {
                  return "Pending"
            }
      }

      const [DateList, setDateList] = useState<any>([]);

      useEffect(() => {
            var boxingDay: any = new Date();
            var nextWeek: any = boxingDay * 1 + 4 * 24 * 3600 * 1000;

            function getDates(d1: any, d2: any) {
                  var oneDay = 24 * 3600 * 1000;
                  for (var d = [], ms = d1 * 1, last = d2 * 1; ms < last; ms += oneDay) {
                        d.push({ isSelected: false, date: new Date(ms).getDate() + '/' + (new Date(ms).getMonth() + 1) });
                  }
                  return d;
            }

            const list = getDates(boxingDay, nextWeek)
            setDateList(list)

      }, []);
      
      return (
            <View style={styles.desktopContainer}>
                  <DesktopHeader heading={'History'}  navigation={navigation} />
                  <View style={styles.bottomDesktopCard}>
                        <DesktopMenue navigation={navigation} />
                        <View style={{ flex: 0.85}}>
                        <View style={styles.outerCard}>
                              <Text style={{ fontSize: 20, color: '#4e3e71', fontWeight: 'bold' }}>Search Surgery </Text>
                              <View style={{ flexDirection: 'row' }}>
                                    <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'space-between' }}>
                                          <View style={styles.searchBox}>
                                                <TextInput
                                                      style={styles.input}
                                                      placeholder="Enter Patient Name "
                                                      autoCapitalize="none"
                                                      autoCorrect={false}
                                                      clearButtonMode="always"
                                                      placeholderTextColor="#939ca3"
                                                      value={query}
                                                      onChangeText={queryText => handleSearch(queryText)}
                                                />
                                                <TouchableOpacity onPress={() => setQuery('')}>
                                                      <img src={query === '' ? require("../assets/search.png") : require("../assets/cross.png")} style={{ height: 30, width: 30, paddingRight: 15, paddingTop: 10 }} />
                                                </TouchableOpacity>
                                          </View>

                                    </View>
                              </View>

                              {loader ? <ActivityIndicator
                                    style={{ margin: 'auto', height: 100, width: 100, }}
                                    size="large" color="#4e3e71" />
                                    : <View style={styles.card}>
                                    <View style={{ flex: 1, }}>
                                          {
                                                (query.length > 0 && data.length > 0) ?
                                                      <View style={styles.add}>
                                                            <Text style={styles.resultText}>{`Showing result "${query}"`}</Text>
                                                      </View>
                                                      :
                                                      (query.length > 0 && data.length === 0) ?
                                                            <View style={styles.add}>
                                                                  <Text style={styles.resultText}>Searched Booking not found</Text>
                                                            </View> : null
                                          }

                                          <FlatList
                                                style={{ height: 562 }}
                                                ListEmptyComponent={ListEmptyView()}
                                                data={data}
                                                keyExtractor={(item: any) => item.id}
                                                horizontal={false}
                                                numColumns={3}
                                                renderItem={({ item }) => (
                                                      <TouchableOpacity onPress={() => goToNextPage(item)}>
                                                            <View style={{ marginVertical: 5 }}>
                                                                  <View style={styles.bookingList}>
                                                                        <View style={{ flexDirection: 'row' }}>
                                                                              <img src={item.attributes.patient_image ? item.attributes.patient_image : item.attributes.patient_gender === 'Male' ?  require("../assets/patient_male.png") :require("../assets/patient_female.png") }
                                                                              style={{ height: 70, width: 70, borderRadius: 12 }} />
                                                                              <View style={{ flexDirection: 'column', marginVertical: 'auto' }}>
                                                                                    <Text style={styles.cardHeading}>{item.attributes.first_name+" "+item.attributes.last_name}</Text>
                                                                                    <Text style={styles.paraDis}>{item.attributes.hospital.data?.attributes.name}</Text>
                                                                              </View>
                                                                        </View>
                                                                        <View style={{ flexDirection: 'row', justifyContent: 'space-between', paddingTop: 15 }}>
                                                                              <Text style={{ color: '#b2bac6', fontSize: 12 }}>Surgery Date & time:</Text>
                                                                              <Text style={{ color: '#5e6f88', fontSize: 12 }}>{getBookingDate(item.attributes.booking_datetime)} at {getBookingTime(item.attributes.booking_datetime)}</Text>
                                                                        </View>
                                                                  </View>
                                                                  <View style={styles.bookingList2}>
                                                                        <Text style={styles.statusBTn}>{BookingStatus(item)}</Text>
                                                                        <Text style={{ fontSize: 12, color: '#4e3e71' }}>View Details</Text>
                                                                  </View>
                                                            </View>
                                                      </TouchableOpacity>

                                                )}
                                          />
                                   
                                   <View style={{flexDirection: 'row', justifyContent: 'space-around'}}>
                        {offset < 1 ? null : <TouchableOpacity onPress={() => setOffset(offset-12)}><Text style={{ color: '#4e3e71', fontWeight: "bold"}}>{"< Prev"}</Text></TouchableOpacity>}
                        {data.length < 12 ? null :  <TouchableOpacity onPress={() => setOffset(offset+12)}><Text style={{ color: '#4e3e71', fontWeight: "bold"}}>{"Next >"}</Text></TouchableOpacity>}
                      </View>
                                   
                                   
                                    </View>

                              </View> }

                        </View>
                        </View>
                  </View>
            </View>
      )
}

const styles = StyleSheet.create({
      desktopContainer: {
            flex: 1,
            flexDirection: 'column'
      },
      paraDis: {
            color: '#8e9aab',
            paddingHorizontal: 15,
            fontSize: 14
      },
      cardHeading: {
            paddingHorizontal: 15,
            color: '#4e3e71',
            fontSize: 17,
      },
      bookingList: {
            padding: 20,
            width: 400,
            marginHorizontal: 5,
            borderColor: '#e5e5e5',
            borderWidth: 1,
            borderTopLeftRadius: 12,
            borderTopRightRadius: 12
      },
      statusBTn: {
            paddingVertical: 5,
            paddingHorizontal: 10,
            fontSize: 12,
            backgroundColor: '#4e3e71',
            color: '#fff',
            borderRadius: 12
      },
      bookingList2: {
            paddingHorizontal: 20,
            paddingVertical: 15,
            marginHorizontal: 5,
            borderColor: '#e5e5e5',
            borderWidth: 1,
            borderBottomLeftRadius: 12,
            borderBottomRightRadius: 12,
            flexDirection: 'row',
            justifyContent: 'space-between'
      },
      add: {
            flexDirection: 'row',
            justifyContent: "center"
      },
      resultText: {
            paddingVertical: 5,
            fontSize: 18

      },
      card: {
            flex: 1,
            // height: 300,
            marginTop: 10,
            borderRadius: 6,
            elevation: 3,
            backgroundColor: '#fff',
            marginVertical: 6,
      },
      heading: {
            marginTop: 100,
            marginHorizontal: 'auto',
            color: '#b2bac6',
            fontSize: 24,
            fontWeight: 'bold',
            flexDirection: 'row',
            justifyContent: 'center'
      },
      searchBox: {
            flex: 1,
            flexDirection: 'row',
            marginVertical: 20,
            borderWidth: 1,
            borderColor: '#8a8a8a',
            borderRadius: 50
      },
      input: {
borderWidth: 0,
            flex: 1,
            paddingLeft: 10,
            height: 48,
            color: '#4e3e71',
            fontSize: 16,
            paddingStart: 20,
            // width: '100%'
      },
      bottomDesktopCard: {
            flex: 1,
            flexDirection: 'row'
      },
      outerCard: {
            flex: 1,
            backgroundColor: 'white',
            borderWidth: 1,
            borderRadius: 12,
            borderColor: '#7f7f7f',
            padding: 15,
            margin: 15,
            flexDirection: 'column'

      }

})