import React, {useEffect, useState} from "react";
// Customizable Area Start
import {
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  TextInput,
  Image,
  ScrollView,
  TouchableWithoutFeedback,
  Platform,
  FlatList,
  ActivityIndicator
} from "react-native";
import DesktopHeader from "./DesktopHeader"
import DesktopMenue from "./DesktopMenue";
import scale from "../../../framework/src/Scale";
import { getAxiosAPI, getErrorMessage, patchAxiosAPI, postAxiosAPI } from "../../../framework/src/Blocks/AxiosAPIBlock";
import CustomPopUp from "../../../framework/src/CustomPopUp";


export default function DesktopSurgeryDetails({ navigation, list }: { navigation: any, list : any }) {

      const [ data, setData] = useState([])
      const [query, setQuery] = useState("");
      const [searchData, setsearchData] = useState([]);
      const [loader, setLoader] = useState(false);
      const [brand_id, setbrand_id ] = useState('');
      const [offset, setOffset] = useState(0);
      const [message, setMessage] = useState("");
      const [popup, setPopup] = useState(false)

      // console.log(DataImportexportcsvController)

      useEffect(() => {
            getManufaclist()
      },[])

      const ClosePopUp = () => {
        setPopup(false)
      }

      const getManufaclist =  async (search: any = '') => {
            setLoader(true)
            let url = `/account_block/accounts/user_selected_brands`
            await getAxiosAPI(url).then((res: any) => {
                  if(res.error){
                    let msg = getErrorMessage(res.data.errors[0])
                    console.log(res.data);
                    setLoader(false)
                  } else {
                    setLoader(false)
                    setData(res.data.data.sort(function (a: any, b: any) 
                    { return (a.attributes.name.toLowerCase() > b.attributes.name.toLowerCase())
                       ? 1 : ((b.attributes.name.toLowerCase() > a.attributes.name.toLowerCase())
                        ? -1 : 0); }))
                  }
                })
              }
      
      const handleSearch = (text: any) => {
            setQuery(text)
            if (text !== "") {
                  const newData = data.filter((name: any) => {
                        return Object.values(name.attributes)
                              .join(" ")
                              .toLowerCase()
                              .includes(text.toLowerCase());
                  });
                  setsearchData(newData);
                  //console.log(newData);

            } else {
                  setsearchData(data);
            }
      };

      const DeleteBrand = async (id: any) => {
            console.log(id)
            let url =  `/account_block/accounts/remove_brand_sales_rep?brand_id=${id}`
            await patchAxiosAPI(url).then((res:any) => {
                  if(res.error){
                        let msg = getErrorMessage(res.data.errors[0])
                        setMessage(msg)
                        setPopup(true)
                  } else {
                        getManufaclist()
                        console.log(res.data)
                        setMessage("Manufacturer deleted successfully")
                        setPopup(true)
                  }
            })

      }

      

      const ListEmptyView = () => {
            return (
                  <View>
                        <Text style={styles.heading}>No Manufacturer Found</Text>
                  </View>

            )
      }


          const navigateTo = async() => {
            let url = "/account_block/accounts/select_brands_for_sales_rep";
            var data = new FormData();
            data.append("brand_ids", brand_id);
            
            await postAxiosAPI(url, data).then((res: any) => {
              if(res.error){
                let msg = getErrorMessage(res.data.errors[0])
                    console.log(res.data);
              } else {
                  localStorage.setItem("brandID", brand_id);
                  navigation.navigate('SelectMaterial')
              }
            });
           
          }


      return (
        window.screen.width < 950 ? 
        <View style={styles.container}>
           {popup && <CustomPopUp ClosePopUp={ClosePopUp} btnText={"Back"} message={message}/>}
           <View style={styles.headingView}>
        <TouchableOpacity onPress={() => navigation.goBack()}>
          <img src={require("../assets/leftArrow1.png")} style={{ height: 24, width: 24, marginLeft: 24 }} />
        </TouchableOpacity>
        <Text style={styles.heading01}>Your Manufacturers</Text>
    <TouchableOpacity onPress={() => navigation.navigate('SalesSelectManufacture')}>
          <img  src={require("../../email-account-registration/assets/add.png")}  style={{height: 24, width: 24, paddingRight: 24}} />
          </TouchableOpacity>
          </View>
  { loader ? <ActivityIndicator
      style={{ margin: 'auto', height: 100, width: 100, }}
      size="large" color="#4e3e71" />
      : data.length < 1 ?

  <View style={styles.errormsg}>
    <Text style={styles.lableText}> You have not added any Manufacturers  </Text>
    <TouchableOpacity onPress={() => navigateTo()}>
      <Text style={{marginHorizontal: 'auto', color: '#4e3e71', fontSize: 20, fontWeight: 'bold'}}>Add Manufacturers</Text>
    </TouchableOpacity>
  </View>
  
    : <ScrollView style={{flex: 1, marginTop: 20}}>
 
                <FlatList
                          data={data}
                          keyExtractor={(item: any) => item.id}
                          renderItem={({ item }) => (
                            <TouchableOpacity>
                                <View style={styles.listItem}>
                                    <View style={{flexDirection: 'row' , flex: 0.9}}>
                                      <img src={item.attributes.hospital?.data?.attributes.logo ? item.attributes.hospital?.data?.attributes.logo :  require("../assets/hospital copy.png")} style={{ height: 56, width: 56}}/> 
                                          <View style={{flexDirection: 'column', marginVertical: 'auto',}}>
                                           <Text style={styles.names}>{item.attributes.name}</Text>
                                                <Text style={styles.roleMob} numberOfLines={2}>{item.attributes.address}</Text>
                                            </View>
                                    </View>
                                    {/* {
                                      role_id === '1' ?  */}
                                      <TouchableOpacity onPress={() => DeleteBrand(item.id)} style={{marginVertical: 'auto', flex: 0.1}}>
                                      <img src={require("../assets/delete.png")}  style={{height: 26, width: 26 }}/>      
                                </TouchableOpacity>
                                {/* : null
                                    } */}
                                   
                                </View>
                            </TouchableOpacity>
                    
                          )}
                          /> 
  </ScrollView>}     
        </View>
      :
                <View style={styles.desktopContainer}>
                        {popup && <CustomPopUp ClosePopUp={ClosePopUp} btnText={"Back"} message={message}/>}
          <DesktopHeader  heading={"Your Manufacturers"} navigation={navigation} />
          <View style={styles.bottomDesktopCard}>
            <DesktopMenue navigation={navigation} />
            <View style={{ flex: 0.85}}>
                        <View style={styles.outerCard}>   
                        <Text style={{ fontSize: 20, color: '#4e3e71', fontWeight: 'bold' }}>Search Manufacturer </Text>
                              {/* <View style={{ flexDirection: 'row' }}>
                                    <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'space-between' }}>
                                          <View style={styles.searchBox}>
                                                <TextInput
                                                      style={styles.input}
                                                      placeholder="Enter Manufacturer Name "
                                                      autoCapitalize="none"
                                                      autoCorrect={false}
                                                      clearButtonMode="always"
                                                      placeholderTextColor="#939ca3"
                                                      value={query}
                                                      onChangeText={queryText => handleSearch(queryText)}
                                                />
                                                <TouchableOpacity onPress={() => setQuery('')}>
                                                      <img src={query === '' ? require("../assets/search.png") : require("../assets/cross.png")} style={{ height: 30, width: 30, paddingRight: 15, paddingTop: 10 }} />
                                                </TouchableOpacity>
                                          </View>
                                            
                                     </View>
                        </View> */}

                                          {loader ? <ActivityIndicator
                                    style={{ margin: 'auto', height: 100, width: 100, }}
                                    size="large" color="#4e3e71" />
                                    : <View style={styles.card}>
                                    <View style={{ flex: 1, }}>
                                          {
                                                (query.length > 0 && searchData.length > 0) ?
                                                      <View style={styles.add}>
                                                            <Text style={styles.resultText}>{`Showing result "${query}"`}</Text>
                                                      </View>
                                                      :
                                                      (query.length > 0 && searchData.length === 0) ?
                                                            <View style={styles.add}>
                                                                  <Text style={styles.resultText}>Searched Manufacturer not found</Text>
                                                            </View> : null
                                          }
                                          <FlatList
                                                style={{ height: 500 }}
                                                ListEmptyComponent={ListEmptyView()}
                                                data={query.length < 1 ? data : searchData}
                                                keyExtractor={(item: any) => item.id}
                                                horizontal={false}
                                                numColumns={3}
                                                renderItem={({ item }) => (
                                                <View >
                                                      <View style={item.ischecked ? styles.listItem_active : styles.listItem}>
                                                      <View style={{flexDirection: 'row' , flex: 0.9}}>
                                                                  <img src={require("../assets/manifac_img.png")} style={{ height: 56, width: 56}}/> 
                                                                  <View style={{flexDirection: 'column', marginVertical: 'auto',}}>
                                                                  <Text style={styles.names} numberOfLines={1}>{item.attributes.name} </Text>    
                                                                  <Text style={styles.role} numberOfLines={3}>{item.attributes.address}</Text>
                                                                  </View>
                                                      </View>
                                                      <TouchableOpacity  onPress={() => DeleteBrand(item.id)} style={{marginVertical: 'auto', flex: 0.1}}>
                                                      <img src={require("../assets/delete.png")}  style={{height: 26, width: 26, borderRadius: '50%' }}/>      
                                                      </TouchableOpacity>
                                                      </View>
                                                </View>
                                                )}
                                          />
                                    </View>
                                    <View style={{ flexDirection: 'row', justifyContent: 'flex-end'}}>
                                    <TouchableOpacity onPress={() => navigation.navigate('SalesSelectManufacture')}>
                                                <View style={styles.button}>
                                                      <Text style={styles.buttonText}>Add Manufacturer</Text>
                                                </View>
                                          </TouchableOpacity>   
                                    </View>
                                    </View>}
                                    </View>
                              </View>
                        
                      
                  </View>
                  </View>
      )
}

const styles = StyleSheet.create({
      desktopContainer: {
            flex: 1,
            flexDirection: 'column'
      },
      leftArrowIconWrapper: {
            width: 24,
            height: 24,
            position: "absolute",
            left: 24
          },
      lableText:{
            marginHorizontal: 'auto',
            fontSize: 18,
            textAlign: "left",
            color: '#4e3e71'
      },
      errormsg: {
            flex: 1,
            flexDirection: 'column',
            justifyContent: 'center',
          }, 
      headingView: {
            flexDirection: 'row',
            alignItems: 'center',
            paddingVertical: 24,
            backgroundColor: '#4e3e71',
          },
      container: {
            flex: 1,
            padding: 1,
            backgroundColor: "#ffff"
      },
      button0: {
            borderRadius: 12,
            paddingVertical: 15,
            paddingHorizontal: 50,
            marginVertical: 10,
            backgroundColor: '#aa9cc9',
            marginHorizontal: 10
      },
      buttonText0: {
            color: '#e8fafe',
            fontSize: 17,
            textAlign: 'center',
      },
      button: {
            borderRadius: 12,
            paddingVertical: 15,
            paddingHorizontal: 50,
            marginVertical: 10,
            backgroundColor: '#4e3e71',
            marginHorizontal: 10
      },
      buttonText: {
            color: '#e8fafe',
            fontSize: 17,
            textAlign: 'center',
      },
      add: {
            flexDirection: 'row',
            justifyContent: "center"
      },
      resultText: {
            paddingVertical: 10,
            fontSize: 18

      },
      card: {
            flex: 1,
            // height: 300,
            marginTop: 10,
            borderRadius: 6,
            elevation: 3,
            backgroundColor: '#fff',
            marginVertical: 6,
      },
      heading: {
            marginTop: 100,
            marginHorizontal: 'auto',
            color: '#b2bac6',
            fontSize: 24,
            fontWeight: 'bold',
            flexDirection: 'row',
            justifyContent: 'center'
      },
      heading01:{
            color: '#e8fafe',
            fontSize: 20,
            marginHorizontal: 'auto'       
          },
      listItem: {
            height: 300,
            width: 360,
            marginHorizontal:12,
            borderColor: '#e5e5e5',
            borderWidth: 1,
            borderRadius: 12,
            marginVertical: 5,
            padding: 10,
            flex: 1,
            flexDirection: 'row',
            justifyContent: 'space-between'
          },
          listItem_active: {
            height: 300,
            width: 360,
            marginHorizontal:12,
            borderColor: '#4e3e71',
            borderWidth: 1,
            borderRadius: 12,
            marginVertical: 5,
            padding: 10,
            flex: 1,
            flexDirection: 'row',
            justifyContent: 'space-between'
          },
          role: {
            color: '#b2bac6',
            paddingHorizontal: 11,
            width: scale(60),
            
          },
          roleMob: {
            color: '#b2bac6',
            paddingHorizontal: 11,
            width: scale(250),
            
          },
          names: {
            color: '#4e3e71',
            fontSize: 15,
            paddingHorizontal: 11,
            width: scale(220),
            
          },
          
      searchBox: {
            flex: 1,
            flexDirection: 'row',
            marginVertical: 20,
            borderWidth: 1,
            borderColor: '#8a8a8a',
            borderRadius: 50
      },
      input: {
borderWidth: 0,
            flex: 1,
            paddingLeft: 10,
            height: 48,
            color: '#4e3e71',
            fontSize: 16,
            paddingStart: 20,
            // width: '100%'
      },
       bottomDesktopCard: {
            flex: 1,
            flexDirection: 'row'
      },
        outerCard: {
            flex: 1,
            backgroundColor: 'white',
            borderWidth: 1,
            borderRadius: 12,
            borderColor: '#7f7f7f',
            padding: 15,
            margin: 15,
            flexDirection: 'column'
            
      }
      
})