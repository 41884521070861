import React, {useState,  useEffect} from 'react';
import {StyleSheet,
Text,
View,
TouchableOpacity,
ActivityIndicator,
TextInput,
FlatList,
ScrollView,} from 'react-native';
import DesktopDesign from "./DesktopTracking";
import { getAxiosAPI, getErrorMessage } from '../../../framework/src/Blocks/AxiosAPIBlock';
import { getBookingDate, getBookingTime } from '../../../framework/src/Utilities';



export default function ExBooking({navigation}: {navigation: any}) {
      const [data, setData] = useState([]);
      const [query, setQuery] = useState("");
      const [searchData, setsearchData] = useState([]);
      const [filterBookings, setFilterBookings] = useState([]);
  const [filtring, setFiltering] = useState(false);
  const [loader, setLoader] = useState(true);

    console.log(filtring)
      useEffect(() => { 
            const getorders = async () => {
              let url = `/bx_block_order/orders`
              await getAxiosAPI(url).then((res: any) => {
                    if(res.error){
                      let msg = getErrorMessage(res.data.errors[0])
                      console.log(msg)
                    } else {
                      console.log(res.data.data);
                      //setData(res.data.data);
                      setLoader(false)
                      setData(res.data.data.filter((x: any) => x.attributes.booking_datetime > new Date().getTime().toString()))                }
                  })
            };
            getorders()
          }, []);

          const goToNextPage = (item: any) => {
            localStorage.setItem('previous_booking', JSON.stringify(item));
            navigation.navigate('Tracking2')
          } 

          const handleSearch = (text : any) => {
            setQuery(text)
            if(text !== "") {
                  const newData = data.filter((name: any) => {
                  return Object.values(name.attributes)
                        .join(" ")
                        .toLowerCase()
                        .includes(text.toLowerCase());
                  });
                  setsearchData(newData);
                  //console.log(newData);
                  
            } else {
              setsearchData(data);
            }
          };

          const ListEmptyView = () => {
            return (
              <View>
                <Text style={styles.heading2}>No Booking Found</Text>
                </View>

            )
          }
  
        const BookingStatus = (item: any) => {
          if (item.attributes.confirmed_by_bc === true &&
          item.attributes.confirmed_by_material_manager === true &&
          item.attributes.confirmed_by_sales_rep === true) {
            return "Confirmed"
          } else {
            return "Pending"
          }
          }
  
  return (
    window.screen.width < 950 ? 
            <View style={styles.container}>
          <View style={styles.headingView}>
            <TouchableOpacity style={styles.leftArrowIconWrapper} onPress={() => navigation.goBack()}>
              <img src={require("../assets/leftArrow1.png")} style={{
                height: 24, width: 24,
              }} />
            </TouchableOpacity>
    <Text style={styles.heading}>Track Status</Text>
         
      </View>
      <View style={styles.searchBox}>
                <TextInput
                          style={styles.input}
                          placeholder="Search "
                          autoCapitalize="none"
                          autoCorrect={false}
                          clearButtonMode="always"
                          placeholderTextColor="#939ca3"
                          value={query}
                          onChangeText={queryText => handleSearch(queryText)}
                  />
                <TouchableOpacity onPress={() => setQuery('')}>
              <img src={query === '' ? require("../assets/search.png" ) : require("../assets/cross.png" )} style={{height: 30, width: 30, paddingRight: 15, paddingTop: 10}}/>
              </TouchableOpacity> 
              </View>  

          {loader ? <ActivityIndicator
            style={{ margin: 'auto', height: 100, width: 100, }}
            size="large" color="#4e3e71" />
            :

            <View style={{ flex: 1 }}>
              <ScrollView>
                <FlatList
                  data={query.length < 1 ? filtring ? filterBookings : data : searchData}
                  keyExtractor={(item: any) => item.id}
                  ListEmptyComponent={ListEmptyView()}
                  renderItem={({ item }: { item: any }) => (
                    <TouchableOpacity onPress={() => goToNextPage(item)}>
                      <View style={{ marginVertical: 15 }}>
                        <View style={styles.bookingList}>
                          <View style={{ flexDirection: 'row' }}>
                            <img src={item.attributes.patient_image ? item.attributes.patient_image : item.attributes.patient_gender === 'Male' ?  require("../assets/patient_male.png") :require("../assets/patient_female.png") }
                            style={{ height: 70, width: 70, borderRadius: 12 }} />
                            <View style={{ flexDirection: 'column', marginVertical: 'auto' }}>
                              <Text style={styles.cardHeading}>{item.attributes.first_name+" "+item.attributes.last_name}</Text>
                              <Text style={styles.paraDis}>{item.attributes.hospital.data?.attributes.name}</Text>
                            </View>
                          </View>
                          <View style={{ flexDirection: 'row', justifyContent: 'space-between', paddingTop: 15 }}>
                            <Text style={{ color: '#b2bac6', fontSize: 12 }}>Surgery Date & time:</Text>
                            <Text style={{ color: '#5e6f88', fontSize: 12 }}>{getBookingDate(item.attributes.booking_datetime)} at {getBookingTime(item.attributes.booking_datetime)}</Text>
                          </View>
                        </View>
                        <View style={styles.bookingList2}>
                          <Text style={styles.statusBTn}>{BookingStatus(item)}</Text>
                          <Text style={{ fontSize: 12, color: '#4e3e71' }}>View Details</Text>
                        </View>
                      </View>
                    </TouchableOpacity>
                  )}
                />
              </ScrollView>
            </View>
          }
                  

      </View>
      :
      <DesktopDesign navigation={navigation} />
      )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
    flexDirection: 'column'
  },
  leftArrowIconWrapper: {
    width: 24,
    height: 24,
    position: "absolute",
    left: 24
  },
  dates:{
    flexDirection: 'row',
    marginHorizontal: 'auto',
  }, 
  statusBTn:{
    paddingVertical: 5, 
    paddingHorizontal: 10,
    fontSize: 12,
    backgroundColor: '#4e3e71', 
    color: '#fff', 
    borderRadius: 12
  },
  outerCard : {
    flexDirection: 'row',
    marginTop:20,
    borderRadius: 15,
    elevation: 3,
    backgroundColor: '#fff',
    marginHorizontal: 20,
    marginVertical: 6,
  },
  bookingList: {
    padding: 20,
    marginHorizontal: 20,
    borderColor: '#e5e5e5',
   borderWidth: 1,
   borderTopLeftRadius: 12,
   borderTopRightRadius: 12
  },
  bookingList2: {
    paddingHorizontal: 20,
    paddingVertical: 15,
    marginHorizontal: 20,
    borderColor: '#e5e5e5',
   borderWidth: 1,
   borderBottomLeftRadius: 12,
   borderBottomRightRadius: 12,
   flexDirection: 'row',
   justifyContent: 'space-between'
  },
  innerCard: {
    margin:10,
    borderRadius: 15,
    elevation: 3,
    backgroundColor: "#eff8ff",
    padding: 25,

  },
  paraDis: {   
    color: '#8e9aab',
    paddingHorizontal: 15,
    fontSize: 14
  },
  para: {
    marginVertical: 35,    
    color: '#0778df',
    marginLeft: 100,
  },
  cardHeading: {
    paddingHorizontal: 15,
    color: '#4e3e71',
    fontSize: 17,
},     
headingView: {
  flexDirection: 'row',
  alignItems: 'center',
  paddingVertical: 27,
  backgroundColor: '#4e3e71',
  marginBottom: 20,
  
  },
  heading:{
  color: '#e8fafe',
  fontSize: 20,
  marginHorizontal: 'auto'       
  },
heading2:{
marginVertical: 100,
marginHorizontal: 'auto',
color: '#b2bac6',
fontSize: 24,
fontWeight: 'bold',
flexDirection : 'row',
justifyContent: 'center'
},
crossIcon: {
position: 'absolute',
top: 40,
right: 10,
elevation: 10,
},      
searchBox: {
flexDirection: 'row',
marginHorizontal:26,
marginVertical: 10,
borderWidth: 1,
borderColor: '#8a8a8a',
borderRadius: 50

},
input: {
borderWidth: 0,
  flex: 1,
paddingLeft: 10,
height: 48,
color: '#4e3e71',
fontSize: 16,
paddingStart: 20
},
  button: {
    borderRadius: 5,
    paddingVertical: 10,
    paddingHorizontal: 15,
    marginHorizontal: 5,
    backgroundColor: 'white',
  },
  buttonText: {
    color: '#0778df',
    fontSize: 15,
    textAlign: 'center',
  },
  button3: {
    borderRadius: 5,
    paddingVertical: 5,
    paddingHorizontal: 5,
    marginHorizontal: 5,
    backgroundColor: 'white',
  },
  buttonText3: {
    // color: '#0778df',
    fontSize: 15,
    textAlign: 'center',
  },
  button2: {
    borderRadius: 5,
    paddingVertical: 10,
    paddingHorizontal: 15,
    marginHorizontal: 5,
    backgroundColor: '#0778df',
  },
  buttonText2: {
    color: 'white',
    fontSize: 15,
    textAlign: 'center',
  },
 
})